import axios from "axios";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
// import settingsJSON from "../utils/settings.json";
// import A from "../utils/API.js";
import A from "../../utils/urls.utils";
import constantUtils from "../../utils/constantUtils";

// import useAdmin from "./useAdmin";
import { settingsAtom } from "../../../state";

export default function useSettings() {
  const [settings, setSettings] = useRecoilState(settingsAtom);
  // const [settings, setSettings] = useState();
  // const { authFailure } = useAdmin();
  // alert(settings);

  const fetchSettings = async () => {
    try {
      if (!settings) {
        const client_secretKey = process.env.REACT_APP_CLIENT_SECRETKEY; // localhost ,zervx-development,starmov
        // if (client_secretKey) {
        const { data } = await axios.get(
          A.HOST +
            A.REACT_CONFIG +
            "?clientSecretKey=" +
            client_secretKey +
            "&type=" +
            constantUtils.CONST_WEB_OS
        );
        if (data) {
          document
            .getElementById("favicon")
            .setAttribute("href", data?.data?.favicon);
          // console.log(data);

          setSettings(data.data);
          localStorage.setItem("web", JSON.stringify(data?.data?.mapApi?.web));
          return data;
        }
      }
    } catch (err) {
      alert("Server Under Construction, Retry later ! ");
      //   authFailure(err);
      return false;
    }
  };

  useEffect(() => {
    // alert(settings);
    fetchSettings();
  }, []);

  const defaultDialCode =
    settings && settings.isCountryCodeNeeded ? settings.defaultDialCode : "";
  return { settings, setSettings, fetchSettings, defaultDialCode };
}
