import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../../utils/navLinks";
import axios from "axios";
import { useParams } from "react-router";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import {
  FiThumbsDown,
  FiKey,
  FiPhone,
  FiStar,
  FiPercent,
  FiLifeBuoy,
  FiTriangle,
} from "react-icons/fi";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import A from "../../utils/urls.utils";
import useAdmin from "../hooks/useAdmin";
import { SmallLoader } from "../common/SmallLoader";
import { CONST_USERCANCELLED } from "../../utils/constantUtils";

export default function UpcomingRides() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { header, user } = useAdmin();
  const { bookingId } = useParams();
  const navigate = useNavigate();

  const [scheduleRideHistoryData, setScheduleRideHistoryData] = useState([]);
  const [isScheduleRide, setIsScheduleRide] = useState(true);
  const [schedulePop, setSchedulePop] = useState(true);

  useEffect(() => {
    const rideHistory = async () => {
      try {
        const { data } = await axios.post(
          A.HOST + A.GET_SCHEDULE_LIST,
          { skip: 0, limit: 30 },
          header
        );
        // setIsScheduleRide(true);
        console.log(data);
        setScheduleRideHistoryData(data.data);
        // console.log(data.data);
        // alert(JSON.stringify(data));
        setSchedulePop(data);

        // alert(data.data);
        // alert("rideHistoryData" + JSON.stringify(rideHistoryData));
        // data.data.map((each) => {
        //   alert(JSON.stringify(each.bI));
        // });
      } catch (err) {
        // alert(err);
        setIsScheduleRide(true);
      }
    };
    rideHistory();
  }, []);
  const cancelRide = async (id) => {
    const data = await axios.post(
      A.HOST + A.BOOKING_CANCEL_BEFORE_START_RIDE,
      {
        bookingId: id,
        denyType: CONST_USERCANCELLED,
      },
      header
    );
    window.location.reload();

    // navigate(NavLinks.HOME);
  };

  useEffect(() => {
    // alert(isScheduleRide);
    if (scheduleRideHistoryData) {
      setIsScheduleRide(false);
    } else {
      setIsScheduleRide(true);
    }
    // console.log(scheduleRideHistoryData);
  }, []);

  return (
    <main className="zervx_main">
      <div className="zervx_content">
        <section className="header-outer">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-3 col-3">
                <div className="header-arrow">
                  <Link to={NavLinks.HOME}>
                    <svg
                      className="ola-svg ola-icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                      </g>
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-6 px-0">
                <div className="header-logo text-center ">
                  <h5>{language.UPCOMING_RIDES}</h5>
                </div>
              </div>
              <div className="col-md-3 col-3">
                {/* <!-- <div className="header-login">
                        <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

                     </div> --> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Location_main_outer --> */}
        <section className="your-rides-outer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {
                  scheduleRideHistoryData.length > 0 ? (
                    <div className="py-4">
                      <>
                        {scheduleRideHistoryData?.map(
                          (bookingHistory, index) => (
                            <>
                              {new Date(bookingHistory.bD) > new Date() ? (
                                <div
                                  className="  your-rides-box rounded-xl my-2 shadow-xl"
                                  key={index + 1}
                                >
                                  <div className="col-md-12 px-0 flex ">
                                    <div className="icon-div col-md-2 col-2 flex items-center">
                                      {/* <img src="images/ic_auto.png" /> */}
                                      <img
                                        src={bookingHistory.ve.vCI}
                                        alt=""
                                        className="user_icon"
                                      />
                                    </div>
                                    <div className="your-rides-content col-md-7 col-7">
                                      <div className="your-rides-name">
                                        {/* {bookingHistory.bD} */}
                                        {format(
                                          new Date(bookingHistory.bD),
                                          "d MMM yyy p"
                                        )}
                                      </div>
                                      <div className="desc">
                                        {bookingHistory.ve.vCN +
                                          " " +
                                          bookingHistory.bI}
                                      </div>

                                      <ul>
                                        <li className="start_point">
                                          {bookingHistory.ac.rS[0].addressName}
                                        </li>
                                        {/* <li className="end_point">
                              Gundy, Chennai, Tamil Nadu, Gundy, Chennai,
                              Tamil Nadu, Tamil Nadu...
                            </li> */}
                                      </ul>
                                      <div
                                        className="p-1 m-1 my-2 bg-sky-200 rounded-xl w-20 text-center text-black cursor-pointer font-semibold "
                                        onClick={() => {
                                          cancelRide(bookingHistory._id);
                                        }}
                                      >
                                        {language.CANCEL}
                                      </div>
                                    </div>
                                    <div className="  col-md-3 col-3 px-0">
                                      <div className=" text-center text-black">
                                        {bookingHistory.cS +
                                          " " +
                                          bookingHistory.inv.paA}
                                      </div>
                                      <div className=" flex justify-center">
                                        {/* <img src="images/profile.png" /> */}
                                        <img
                                          src={bookingHistory.us.av}
                                          alt=""
                                          className=" p-1 rounded-full w-20 h-20 border"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                index === 0 && (
                                  <div className="text-center p-5">
                                    <h6>{language.NO_UPCOMING_ORDER}</h6>
                                    <p className="text-sm px-md-5">
                                      {language.ALERT_UPCOMING_RIDES_HISTORY}
                                    </p>
                                  </div>
                                )
                              )}
                            </>
                          )
                        )}
                      </>
                    </div>
                  ) : (
                    // scheduleRideHistoryData.length === 0 &&
                    // (!scheduleRideHistoryData ? (
                    <div className="text-center p-5">
                      <h6>{language.NO_UPCOMING_ORDER}</h6>
                      <p className="text-sm px-md-5">
                        {language.ALERT_UPCOMING_RIDES_HISTORY}
                      </p>
                    </div>
                    // ) : (
                    //   <SmallLoader />
                    // ))
                  )
                  // !schedulePop && (
                  //   <div className="text-center p-5">
                  //     <h6>No Upcoming Orders</h6>
                  //     <p className="text-sm px-5">
                  //       You have no upcoming orders history. Book any service to
                  //       make your life simple
                  //     </p>
                  //   </div>
                  // )
                }
              </div>
            </div>
          </div>
          {/* {!!scheduleRideHistoryData && (
            <div className="text-center p-5">
              <h6>No Upcoming Orders</h6>
              <p className="text-sm px-5">
                You have no upcoming orders history. Book any service to make
                your life simple
              </p>
            </div>
          )} */}
        </section>
      </div>
      <div
        className="zervx_rightimg"
        // style="background-image: url('images/bg_city.jpg');"
      >
        <img
          src={settings?.bookingAppImage}
          alt=""
          className="w-screen h-screen"
        />

        <div className="global-img-right">
          {/* <h2>
            Ride with <span>Comfort</span>
          </h2>
          <h2>
            Ride with <span>ZervX</span>
          </h2> */}
          <h2>{settings?.bookingAppHomePageMessage}</h2>
        </div>
      </div>
    </main>
  );
}
