import React, { createContext, useState, useEffect } from "react";

export const CardDeatilscontext = createContext();

function CardDeatilsProvider({ children }) {
  // const [getdata,setgetdata]=useState()
  // const [loading,setloading]=useState()
  const [CardDeatilsData, setCardDeatilsData] = useState({});

  return (
    <CardDeatilscontext.Provider
      value={{ CardDeatilsData, setCardDeatilsData }}
    >
      {children}
    </CardDeatilscontext.Provider>
  );
}

export default CardDeatilsProvider;
