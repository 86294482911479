const mode = process.env.REACT_APP_CLIENT_KEY || "localhost";
console.log(process.env);
let host;
let SOCKET_HOST_WEB_BOOKING;
switch (mode) {
  case "starmov":
    host = "https://api.appstarmov.com";
    SOCKET_HOST_WEB_BOOKING = "https://socket.appstarmov.com/webBooking";
    break;
  case "pamworld":
    host = "https://api1.pamworld.com";
    SOCKET_HOST_WEB_BOOKING = "https://socket1.pamworld.com/webBooking";
    break;
  case "development":
    host = "https://api.zervx.com";
    SOCKET_HOST_WEB_BOOKING = "https://socket.zervx.com/webBooking";
    break;
  case "wayder":
    host = "https://api.wayder.co.uk";
    SOCKET_HOST_WEB_BOOKING = "https://socket.wayder.co.uk/webBooking";
    break;
  case "demo":
    host = "https://api.demo.zervx.com";
    SOCKET_HOST_WEB_BOOKING = "https://socket.demo.zervx.com/webBooking";
    break;
  case "twende":
    host = "https://api.twendetech.co.tz";
    SOCKET_HOST_WEB_BOOKING = "https://socket.twendetech.co.tz/webBooking";
    break;
  case "olympiahld":
    host = "https://api.wdy.wdy247.co.za";
    SOCKET_HOST_WEB_BOOKING = "https://socket.olympiahld.zervx.com/webBooking";
    break;
  case "digito":
    host = "https://api.digito.co.in";
    SOCKET_HOST_WEB_BOOKING = "https://socket.digito.co.in/webBooking";
    break;
  case "jetridenew":
    host = "https://api2.jetrideapp.com";
    SOCKET_HOST_WEB_BOOKING = "https://socket2.jetrideapp.com/webBooking";
    break;
  case "zayride":
    host = "https://api.fastrack.site";
    SOCKET_HOST_WEB_BOOKING = "https://socket.fastrack.site/webBooking";
    break;
  case "wdy":
    host = "https://api.wdy.wdy247.co.za";
    SOCKET_HOST_WEB_BOOKING = "https://socket.wdy.wdy247.co.za/webBooking";
    break;
  case "cabzone":
    host = "https://api.cabzone.co.uk";
    SOCKET_HOST_WEB_BOOKING = "https://socket.cabzone.co.uk/webBooking";
    break;
  // case "taximobility"://Old
  //   host = "https://api.taximobility.co.il";
  //   SOCKET_HOST_WEB_BOOKING = "https://socket.taximobility.co.il/webBooking";
  case "taximobility":
    host = "https://api.t-mobility.co.il";
    SOCKET_HOST_WEB_BOOKING = "https://socket.t-mobility.co.il/webBooking";
    break;
  default:
    host = "http://localhost:8080";
    // host = "http://192.168.1.38:8080";
    // SOCKET_HOST_WEB_BOOKING = "http://localhost:5000/webBooking";
    SOCKET_HOST_WEB_BOOKING = "https://socket.zervx.com/webBooking";
    // SOCKET_HOST_WEB_BOOKING = "http://localhost:5000/admin";

    break;
}



const urls = {
  MODE: mode,
  HOST: host,
  SOCKET_HOST_WEB_BOOKING: SOCKET_HOST_WEB_BOOKING,
};

const API = {
  // module.exports = {
  // LIVE_HOST: "https://www.berarkrays.in",
  HOST: urls.HOST,
  SOCKET: urls.SOCKET_HOST_WEB_BOOKING,
  //   SOCKET_HOST_ADMIN: urls.SOCKET_HOST_ADMIN,
  //   SOCKET_HOST_NOTIFICATIONS: urls.SOCKET_HOST_NOTIFICATIONS,
  REACT_CONFIG: "/api/admin/reactconfig",
  LANGUAGE_KEYS: "/api/admin/getLanguageKeys",
  TRACK: "/api/booking/track",
  TRACK_CURRENT_LOCATION: "/api/booking/track/location",

  USER_LOGIN_IN_OTP: "/api/user/login",
  USER_LOG_IN_CONFIRM_OTP: "/api/user/verifyOtp",
  USER_VALIDATE: "/api/user/common/loginValidation",
  USER_PROFILE_GET: "/api/user/profile",

  USER_BOOK_DRIVERS_LIST: "/api/booking/getAvailableProfessionalsForGuestUser",
  USER_BOOK_CATEGORIES_GET:
    "/api/category/user/ride/userGetLocationBasedCategoryForGustUser",
  USER_RIDE_BOOKING: "/api/booking/user/ride/userRideBooking",
  TRACK_BOOKING: "/api/booking/trackBookingDetailsByIdFromWebBookingApp",
  BOOKING_CANCEL_BEFORE_START_RIDE: "/api/booking/user/bookingExpiryOrCancel",

  GET_ACCEPT_BOOKING: "/api/booking/getBookingDetailById",
  SUBMIT_RATING: "/api/booking/user/submitRating",
  GET_BOOKING_HISTORY: "/api/booking/user/getOrderAndBookingHistory",
  GET_SCHEDULE_LIST: "/api/booking/userGetScheduleList",
  GETCOUPON_LIST: "/api/admin/getUserBasedCouponList",
  CANCEL_BOOKING: "/api/booking/user/cancelBooking",
  USER_BOOK_RETRY: "/api/booking/user/ride/retryBooking",
  USER_BOOK_RETRY_EPIRED: "/api/booking/user/ride/expiredRetryBooking",
  GET_BOOKING_DEATILS: "/api/booking/getBookingDetail",
  ADD_CARD_DEATILS: "/api/user/add/card",
  VERIFY_CARD_PAYMENT_GATWAY: "/api/transaction/verifyCardByPaymentGatway",
  CET_CARD_LIST: "/api/booking/user/getPaymentOptions",
  CARD_PREAUTH_PAYMENT: "/api/transaction/newBookingCardPayment",
  WALLET_AMOUNT_RECHARGE: "/api/transaction/newRechargeWalletCardPayment",
  ADD_WALLET_AMOUNT: "/api/transaction/newRechargeWalletTransaction",
  GET_WALLET_TRANSACTION_LIST: "/api/transaction/getTransactionDetails",
  CANCELLATIONREASON: "/api/booking/user/getCancellationReason",
  GET_REWARD_LIST: "/api/rewards/getRewardsListByUserType",
  VIEW_RECEIPT: "/api/booking/user/viewRecipt",
  SCHEDULE_CATEGOEYC_CHANGE: "/api/booking/scheduleBookingCategoryChange",
  REMOVE_CARD: "/api/user/remove/card",
};
module.exports = API;
