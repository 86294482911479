import gsap from "gsap/gsap-core";
import React, { useEffect, useState } from "react";
import {
  AiFillExclamationCircle,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { FiBell } from "react-icons/fi";
import { HiCalendar, HiOutlineSpeakerphone } from "react-icons/hi";
import OutsideClickHandler from "react-outside-click-handler";
import axios from "axios";
import useAdmin from "../../hooks/useAdmin";
import useSocket from "../../hooks/useSocket";
import Cookies from "js-cookie";

// import { Howl } from "howler";

// import MainMenuIcons from "../../pages/admin/home/MainMenuIcons";
// import useLanguage from "../../hooks/useLanguage";
// import useSocket from "../../hooks/useSocket";

// import notificationSound from "../../assets/sounds/notification.mp3";

import A from "../../../utils/urls.utils";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import NavLinks from "../../../utils/navLinks";
import useSettings from "../../hooks/useSettings";
import { Modal } from "react-bootstrap";
import language from "../../../utils/language.json";

// import NavLinks from "../../utils/navLinks";
// import useAdmin from "../../hooks/useAdmin";
// import { format } from "date-fns/esm";
// import { useHistory } from "react-router";
// import useUtils from "../../hooks/useUtils";

const Notification = ({
  close,
  notificationData = [],
  removeOnlyMeNotification = (e) => {},
  rideNotification = false,
}) => {
  //   const history = useHistory();
  //   const { truncate } = useUtils();
  //   const { language } = useLanguage();
  React.useEffect(() => {
    gsap.fromTo(
      ".notifications",
      0.2,
      { opacity: 0, y: 80 },
      { opacity: 1, y: 30, stagger: 0.1 }
    );
  }, []);
  return (
    <div className="opacity-0 notifications">
      <h1>hi</h1>
    </div>
  );
};

export default function Index({ bookingId }) {
  const navigate = useNavigate();
  const { settings } = useSettings();

  const [showNotification, setNotification] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const { header, authFailure, token } = useAdmin();
  const [notificationData, setNotificationData] = React.useState([]);
  const [onlyMeNotification, setOnlyMeNotification] = React.useState([]);
  const [firstLoad, setFirstLoad] = React.useState(true);
  //   const { language } = useLanguage();
  const { notificationSocket } = useSocket();
  //   const sound = new Howl({
  //     src: notificationSound,
  //   });
  const [show, setShow] = useState(false);
  const [popUpContent, setPopUpContent] = useState(true);
  const [hearder, sethearder] = useState();

  useEffect(() => {
    sethearder(JSON.parse(localStorage.getItem("CBU")));
  }, []);
  // const fetchData = async () => {
  //   try {
  //     const { data } = await axios.post(
  //       A.HOST + A.ADMIN_DASHBOARD_NOTIFICATION,
  //       {},
  //       header
  //     );
  //     // setNotificationData([
  //     //   ...[
  //     //     ...data.reportsData,
  //     //     ...data.securityData,
  //     //     ...data.scheduleData,
  //     //     ...data.professionalCancelData,
  //     //     ...onlyMeNotification,
  //     //   ]
  //     //     .sort(function (a, b) {
  //     //       return new Date(b.createdAt) - new Date(a.createdAt);
  //     //     })
  //     //     // .map((each) => console.log({ id: each._id, time: format(new Date(each.createdAt), "p") }));
  //     //     .map((each) => {
  //     //       if (each.userType) {
  //     //         return {
  //     //           id: each._id,
  //     //           category: "REPORT",
  //     //           subCategory: null,
  //     //           firstName: each.firstName,
  //     //           lastName: each.lastName,
  //     //           phoneCode: each.phone?.code,
  //     //           phoneNumber: each.phone?.number,
  //     //           comment: each.comment,
  //     //           time: format(new Date(each.createdAt), "do LLL  p"),
  //     //         };
  //     //       } else if (each.bookingFor) {
  //     //         return {
  //     //           id: each._id,
  //     //           name:
  //     //             each.bookingFor.name === "GUEST"
  //     //               ? "GUEST"
  //     //               : each.bookingFor.name,
  //     //           phoneCode: each.bookingFor?.phoneCode,
  //     //           phoneNumber: each.bookingFor?.phoneNumber,
  //     //           bookingStatus: each.bookingStatus,
  //     //           category: "RIDE",
  //     //           time: format(new Date(each.bookingDate), "do LLL  p"),
  //     //         };
  //     //       } else if (each.category === "SECURITY_END") {
  //     //         return {
  //     //           id: each._id,
  //     //           category: "SECURITY_END",
  //     //           firstName: each.firstName,
  //     //           lastName: each.lastName,
  //     //           phoneCode: each.phoneCode,
  //     //           phoneNumber: each.phoneNumber,
  //     //           time: format(new Date(each.createdAt), "do LLL  p"),
  //     //         };
  //     //       } else {
  //     //         return {
  //     //           id: each._id,
  //     //           category: "SECURITY",
  //     //           subCategory: each.alertType,
  //     //           firstName: each.firstName,
  //     //           lastName: each.lastName,
  //     //           phoneCode: each.phone?.code,
  //     //           phoneNumber: each.phone?.number,
  //     //           time: format(new Date(each.createdAt), "do LLL  p"),
  //     //         };
  //     //       }
  //     //     }),
  //     // ]);
  //     // setShowAlert(
  //     //   firstLoad === true &&
  //     //     (data.reportsData.length !== 0 || data.securityData.length !== 0)
  //     //     ? true
  //     //     : false
  //     // );
  //     setFirstLoad(false);
  //   } catch (err) {
  //     //   authFailure(err);
  //     //   setFirstLoad(false);
  //     //   setNotificationData([err]);
  //     setFirstLoad(true ? false : true);

  //     alert(err);
  //     //   window.location.reload(true);
  //     //   setShowAlert(firstLoad === true ? true : false);
  //   }
  // };
  const fetchData = async () => {
    setFirstLoad(true ? false : true);
    Cookies.get("CBU")?.substring(13);
  };

  React.useEffect(() => console.log(notificationData), [notificationData]);

  React.useEffect(() => {
    // setOnlyMeNotification([
    //   ...onlyMeNotification,
    //   {
    //     _id: "",
    //     firstName: "Benzigar",
    //     lastName: "Codes",
    //     phoneCode: "+91",
    //     phoneNumber: "9791442121",
    //     category: "SECURITY_END",
    //     createdAt: new Date(),
    //   },
    // ]);
    // setTimeout(() => {
    notificationSocket.current.on(
      "trackBookingStatusForWebBookingApp",
      (data) => {
        // alert(JSON.stringify(data.bookingStatus));
        if (data.userAccessToken === JSON.parse(localStorage.getItem("CBU"))) {
          switch (data.bookingStatus) {
            case "AWAITING":
              //   navigate(NavLinks.RIDE_BOOKING);
              break;

            case "ACCEPTED":
              navigate(NavLinks.RIDE_CONFIRMED + "/" + data.bookingId);
              break;

            case "ARRIVED":
              navigate(NavLinks.ARRIVED + "/" + data.bookingId);
              break;

            case "STARTED":
              navigate(NavLinks.START_RIDE + "/" + data.bookingId);
              break;

            case "ENDED":
              if (data.bookingInfo.pendingReview) {
                navigate(
                  NavLinks.RIDE_REVIEW + "/" + data.bookingInfo.pendingReview
                );
              } else {
                navigate(NavLinks.HOME);
              }
              break;

            default:
              navigate(NavLinks.HOME);
              break; // check "bookingType" also
          }
        } else {
          navigate(NavLinks.HOME);
        }
      }
      //     },
      //     5000
      //   );
      // console.log(data);
    );
    // notificationSocket.current.on("notifyAdmin", () => {
    //   setShowAlert(true);
    //   sound.play();
    // });
    //-----------------
    // // notificationSocket.current.on(Cookies.get("CBU")?.substring(13), (data) => {
    // //   alert("token" + JSON.stringify(data));
    // //   setNotificationData("45");
    // //   console.log(data);
    // //   console.log(settings);
    // //   // if (data.action === "ESCORTARRIVED") {
    // //   //   setShowAlert(true);
    // //   //   setOnlyMeNotification([
    // //   //     ...onlyMeNotification,
    // //   //     {
    // //   //       _id: data?.details?._id || "",
    // //   //       firstName: data?.details?.user?.firstName || "",
    // //   //       lastName: data?.details?.user?.lastName || "",
    // //   //       phoneCode: data?.details?.user?.phone?.code || "",
    // //   //       phoneNumber: data?.details?.user?.phone?.number || "",
    // //   //       category: "SECURITY_END",
    // //   //       createdAt: new Date(),
    // //   //     },
    // //   //   ]);
    // //   //   // sound.play();
    // //   // }
    // // });
    //-----------------
    // alert("11");
    // console.log(notificationSocket);
    // fetchData();
    // setTimeout(() => {
    //   socket.connect();
    // }, 1000);
  }, [notificationSocket?.current?.connected]);

  // const close = () => {
  //   gsap.fromTo(
  //     ".notifications",
  //     0.2,
  //     { opacity: 1, y: 30 },
  //     { opacity: 0, y: 80, stagger: 0.1 }
  //   );
  //   setTimeout(() => setNotification(false), 400);
  // };

  return (
    // <MainMenuIcons
    //   alert={showAlert}
    //   onClick={() => {
    //     if (showNotification === true) close();
    //     else {
    //       fetchData();
    //       setNotification(true);
    //       setShowAlert(false);
    //     }
    //   }}
    //   icon={notificationData === null ? <AiOutlineLoading3Quarters className="animate-spin" /> : <FiBell />}
    //   children={
    //     showNotification && (
    //       <OutsideClickHandler onOutsideClick={close}>
    //         <Notification
    //           removeOnlyMeNotification={(e) => setOnlyMeNotification(onlyMeNotification.filter((each) => each._id !== e))}
    //           close={close}
    //           notificationData={notificationData}
    //         />
    //         <Notification rideNotification={true} close={close} notificationData={notificationData} />
    //       </OutsideClickHandler>
    //     )
    //   }
    // />
    <div>
      <Modal show={show} onHide={show}>
        {/* <Modals.Header> */}
        <Modal.Title className="text-center">{popUpContent}</Modal.Title>
        {/* </Modals.Header> */}
      </Modal>
      {/* <h1></h1> */}
    </div>
  );
}
