/* eslint-disable react/jsx-pascal-case */
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  redirect as Redirect,
  useLocation,
} from "react-router-dom";
import Cookies from "js-cookie";

import HomePage from "../home/HomePage";
import Login from "../home/Login";
import NavLinks from "../../utils/navLinks";
import UserValidation from "../UserValidation";
import useAdmin from "../hooks/useAdmin";
import RideBooking from "../home/RideBooking";
import Review from "../home/Review.jsx";
import BookingConfirmed from "../home/BookingConfirmed";
import YourRides from "../home/YourRides";
import YourRideDeatils from "../home/YourRideDeatils";
import ViewReceipt from "../home/ViewReceipt";
import UpcomingRides from "../home/UpcomingRides";
import SearchingProfessional from "../home/SearchingProfessional";
import ScheduleRideBooking from "../home/ScheduleRideBooking";
import StartRide from "../home/StartRide";
import CardDeatils from "../home/CardDeatils";
import Profile from "../home/Profile";
import WalletPage from "../home/WalletPage";
import Registration from "../home/Registration";
import Rewards from "../home/Rewards";
import ArrivedBooking from "../home/ArrivedBooking";

// const MainRouters = (path, element) => {
//   const params = new URLSearchParams(window.location.search);

//   const location = useLocation();
//   const { userProfile } = useAdmin();
//   params !== null
//     ? Cookies.set("loc", location.pathname + `?${params}`, { expires: 10 })
//     : Cookies.set("loc", location.pathname, { expires: 10 });

//   if (userProfile) {
//     <Route path={path} element={element} />;
//   } else {
//     <Redirect from={location.pathname} to={"/"} />;
//   }
// };
function MainRouter() {
  return (
    <Router>
      <Routes>
        <Route path={NavLinks.HOME} element={<HomePage />} />
        <Route path={NavLinks.LOG_IN + "/:popUp"} element={<Login />} />
        <Route path={NavLinks.RIDE_BOOKING} element={<RideBooking />} />
        <Route
          path={NavLinks.RIDE_CONFIRMED + "/:bookingId"}
          element={<BookingConfirmed />}
        />
        <Route
          path={NavLinks.RIDE_REVIEW + "/:bookingId"}
          element={<Review />}
        />
        <Route path={NavLinks.YOUR_RIDES} element={<YourRides />} />
        <Route
          path={NavLinks.YOUR_RIDE_DEATILS + "/:bookingId"}
          element={<YourRideDeatils />}
        />
        <Route
          path={NavLinks.YOUR_RIDE_VIEW_RECEIPT + "/:bookingId"}
          element={<ViewReceipt />}
        />
        <Route
          path={NavLinks.YOUR_RIDE_VIEW_UCOMING_RIDES}
          element={<UpcomingRides />}
        />
        <Route
          path={NavLinks.SEARCHING_PROFESSIONALS + "/:bookingId"}
          element={<SearchingProfessional />}
        />
        <Route
          path={NavLinks.SCHEDULE_RIDE_BOOKING + "/:bookingId"}
          element={<ScheduleRideBooking />}
        />
        <Route
          path={NavLinks.START_RIDE + "/:bookingId"}
          element={<StartRide />}
        />
        <Route
          path={NavLinks.CARD_DEATILS + "/:viewBy"}
          element={<CardDeatils />}
        />
        <Route
          path={NavLinks.PROFILE + "/:path/:bookingId"}
          element={<Profile />}
        />
        <Route path={NavLinks.WALLET} element={<WalletPage />} />
        <Route path={NavLinks.REGISTRATION} element={<Registration />} />
        <Route path={NavLinks.REWARDS} element={<Rewards />} />
        <Route
          path={NavLinks.ARRIVED + "/:bookingId"}
          element={<ArrivedBooking />}
        />

        {/* <Route exact path={"/reload"} element={<UserValidation />} /> */}
      </Routes>
    </Router>
  );
}

export default MainRouter;
