import { addDays, isToday, setHours, setMinutes } from "date-fns";
import React from "react";
import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
// import { TextField } from "./TextField";

export default function CalenderDatePicker({
  disableBeforeDays = false,
  afterDays = false,
  defaultValue = false,
  startTime = false,
  timeIntervals = 15,
  change = () => {},
  showTime = false,
  error = "",
  placeholder = "Enter Date",
  ...rest
}) {
  const filterPassedTime = (time) => {
    const currentDate = startTime;
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  return (
    <div className="w-full ">
      <div className={`rounded-lg ${!!error === true && ""} w-full`}>
        {/* border-red-500 */}
        <ReactDatePicker
          // className={`w-full  text-sm focus:outline-none bg-gray-100 rounded-xl px-4 py-3 border-b-2 border-gray-100 dark:border-gray-800 dark:focus:border-blue-800  ${
          //   //dark:bg-gray-900
          //   !!error === false && "shadow shadow_blue"
          // }`} //focus:border-blue-800
          className={`p-2 w-full focus:outline-none bg-gray-200   ${
            //dark:bg-gray-900
            !!error === false && ""
          }`}
          selected={defaultValue}
          timeIntervals={timeIntervals}
          showTimeSelect={showTime}
          dateFormat={showTime ? "MMMM d, yyyy | h:mm aa" : "MMMM d, yyyy"}
          filterTime={startTime ? filterPassedTime : false}
          maxDate={afterDays ? addDays(new Date(), afterDays) : false}
          minDate={disableBeforeDays ? new Date() : false}
          onChange={(e) => change(e)}
          placeholderText={placeholder}
        />
      </div>
      {/* <ReactDatePicker /> */}
      {!!error === true && (
        <div
          style={{ fontSize: 11 }}
          className="text-red-600  dark:text-red-500 text-sm mt-1"
        >
          {error}
        </div>
      )}
    </div>
  );
}
