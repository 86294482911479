import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiThumbsDown, FiStar, FiClose } from "react-icons/fi";
import { FaClosedCaptioning, FaCross } from "react-icons/fa";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import { format } from "date-fns";
import { Loader } from "@googlemaps/js-api-loader";
// import Alert from "react-bootstrap/Alert";
// import Button from "react-bootstrap/Button";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import NavLinks from "../../utils/navLinks";
import useAdmin from "../hooks/useAdmin";
import A from "../../utils/urls.utils";
import { CONST_USERCANCELLED } from "../../utils/constantUtils";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import useSocket from "../hooks/useSocket";
import NavBar from "../navBar/NavBar";
import Notification from "./Notification/index";

export default function SearchingProfessional() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { bookingId, amount } = useParams();
  const { header, user } = useAdmin();
  const navigate = useNavigate();
  const mapArea = React.useRef();
  const { notificationSocket } = useSocket();

  const [userValue, setUserValue] = useState();
  const [pickup, setpickUp] = useState(null);
  const [drop, setdrop] = useState(null);
  const [name, setname] = useState(null);
  const [bookingID, setBookingID] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState({});

  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [map, setMap] = useState(null);
  // const [show, setShow] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const [bookingDeatils, setBookingDeatils] = useState({
    id: "",
    pickUp: null,
    drop: null,
    firstName: "",
    lastName: "",
    bookingID: "",
    bookingOTP: "",
    avatar: "",
    plateNumber: "",
    model: "",
    color: "",
    vehicleCategory: "",
    avgRating: "",
    categoryImage: "",
    pickUpLat: 0,
    pickUpLng: 0,
    dropLat: "",
    dropLng: "",
    bookingDate: "",
    amount: 0,
    currencySymbol: "",
    professionalImg: "",
    professionalRating: [],
  });

  // const getBookingDetailById = async () => {
  //   try {
  //     // alert(JSON.stringify("bookingId" + bookingId));

  //     const { data } = await axios.post(
  //       A.HOST + A.GET_ACCEPT_BOOKING,
  //       { bookingId: bookingId },
  //       header
  //     );
  //     //   setBookedInformation(data.data);
  //     // setBookingDeatils({
  //     //   id: data.data._id,
  //     //   pickUp: data.data.activity.rideStops[0].addressName,
  //     //   drop: data.data.activity.rideStops[1].addressName,
  //     //   firstName: data.data.user.firstName,
  //     //   // lastName: data.data.professional.lastName,
  //     //   bookingID: data.data.bookingId,
  //     //   bookingOTP: data.data.bookingOTP,
  //     //   avatar: data.data.user.avatar,
  //     //   plateNumber: data.data.vehicle.plateNumber,
  //     //   bookingType: data.data.bookingType,
  //     //   dropLat: data.data.destination.lat,
  //     //   dropLng: data.data.destination.lng,
  //     //   pickUpLat: data.data.origin.lat,
  //     //   pickUpLng: data.data.origin.lng,
  //     //   vehicleCategory: data.data.vehicle.vehicleCategoryName,
  //     //   model: data.data.vehicle.model,
  //     //   categoryImage: data.data.vehicle.vehicleCategoryImage,
  //     //   bookingDate: format(new Date(data.data.bookingDate), "d MMM yyy p"),
  //     //   amount: data.data.invoice.payableAmount,
  //     //   currencySymbol: data.data.currencySymbol,
  //     //   // professionalImg: data.data.professional.avatar,
  //     //   // professionalRating: [data.data.professional.review.avgRating],
  //     // });
  //     //   alert(data.data.professional.review.avgRating);
  //     //   alert(JSON.stringify(data.data.destination.addressName));
  //     console.log(data.data);
  //     // setShow(data.data.bs === "EXPIRED" ? true : false);
  //   } catch (err) {
  //     alert(err);
  //     //   console.log(err);
  //   }
  // };
  useEffect(() => {
    window.addEventListener(
      "popstate",
      async (event) => {
        setTimeout(window.history.forward(), 0);
        // console.log(event);
      },
      false
    );
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);
  // useEffect(() => {
  //   window.addEventListener(
  //     "popstate",
  //     async (event) => {
  //       // event.navigation.canGoBack === false;
  //       // if (event.state) {
  //       //do your code here
  //       setTimeout(window.history.forward(), 0);
  //       // setModalShow(true);

  //       // popUpFun();
  //       // cancelRide();
  //       console.log(event);

  //       // setShow(false);
  //       // }
  //     },
  //     false
  //   );
  // }, []);

  const popUpFun = async () => {
    setModalShow(true);
  };

  // useEffect(() => {
  //   console.log(notificationSocket);

  // getBookingDetailById();
  // notificationSocket.current.emit(
  //   "trackBookingDetailsByIdFromWebBookingApp",
  //   { bookingId: bookingId },
  //   (data) => {
  //     console.log(data);
  //   }
  // );
  // notificationSocket.current.on(
  //   "trackBookingDetailsByIdFromWebBookingApp",
  //   (data) => {
  //     console.log(data);
  //   }
  // );
  //   notificationSocket.current.on(
  //     "trackBookingStatusForWebBookingApp",
  //     (data) => {
  //       console.log("__________________");
  //       console.log(data);
  //       console.log("__________________");
  //     }
  //   );
  // }, [bookingId]);

  const cancelRide = async (value) => {
    const data = await axios.post(
      A.HOST + A.BOOKING_CANCEL_BEFORE_START_RIDE,
      {
        bookingId: bookingId,
        denyType: CONST_USERCANCELLED,
      },
      header
    );
    navigate(NavLinks.RIDE_BOOKING);
  };

  return (
    <>
      <Notification />

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {language.ALERT_CANCEL_TRIP}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-gray-500 font-bold">
            {language.ALERT_CANCEL_TRIP_INFO}
          </p>
          {/* <p>Stay logged in to make your life simpler</p> */}

          {/* {cancelReasion
            ? cancelReasion.map((each) => (
                <div
                  className=" cursor-pointer py-2 font-bold hover:border hover:border-green-400 text-center rounded-xl"
                  onClick={() => {
                    cancelRide(each.reason);
                  }}
                >
                  {each.reason}
                </div>
              ))
            : null} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            {language.CANCEL}
          </Button>
          <Button
            onClick={() => {
              cancelRide();
            }}
          >
            {language.CONFIRM}
          </Button>
        </Modal.Footer>
      </Modal>
      <main className="zervx_main ">
        <div className="zervx_content">
          {/* <div className=" relative top-0 ">
            <NavBar path="searchProfessional" bookingId={bookingId} />
          </div> */}
          {/* <section className="header-outer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3 col-3">
                  <div className="header-arrow">
                    <Link to={NavLinks.HOME}>
                      <svg
                        className="ola-svg ola-icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-6">
                  <div className="header-logo text-center">
                    <h5 className=" py-2 text-bold">
                      Connecting you to near by Drivers
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <!-- Review_section_start --> */}
          <div className="flex justify-center items-center pt-72">
            <section className="your_ride_details block items-center  ">
              <div className="container">
                {/* <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="your_ride_details_inner">
                    <div className="date_price">
                      <div className="">
                        
                        {bookingDeatils.bookingDate}
                      </div>
                      <div className="price">
                        <h5>
                          {bookingDeatils.currencySymbol +
                            " " +
                            bookingDeatils.amount}
                        </h5>
                      </div>
                    </div>
                    <div className="trip_location">
                      <h5>Trip Location</h5>

                      <div className="trip_div start">
                        {bookingDeatils.pickUp}
                      </div>
                      <div className="trip_div end">{bookingDeatils.drop}</div>
                    </div>

                    
                  </div>
                </div>
              </div> */}

                <div className=" w-full text-center flex items-center px-5 mt-4">
                  <h6 className=" text-gray-400">
                    {language.ALERT_BOOKING_ALGORITHM}
                  </h6>
                </div>
                <div className="grid grid-cols-2 ">
                  <div id="light">
                    <div id="lineh1"></div>
                  </div>
                  <div id="light">
                    <div id="lineh2"></div>
                  </div>
                </div>

                <div
                  className="flex justify-center text-center text-black cursor-pointer font-semibold pt-5 "
                  onClick={() => {
                    // cancelRide();
                    // CancelReasion();
                    setModalShow(true);
                  }}
                >
                  <div
                    className="p-1 
                rounded-full
                bg-white
                border-black border-2
                "
                  >
                    <span
                      aria-hidden="true"
                      className="
                px-2
                
               "
                    >
                      &times;
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          className="zervx_rightimg"
          // style={"background-image: url('../assets/images/bg_city.jpg')"}
          // id="bg_image"
        >
          {/* <img src={zervx_Bgimg} alt="" /> */}
          <img
            src={settings?.bookingAppImage}
            alt=""
            className="w-screen h-screen"
          />
          {/* <img src={settings.bookingAppImage} alt="" /> */}

          <div className="global-img-right">
            {/* <h2>
              Ride with <span>Comfort</span>
            </h2>
            <h2>
              Ride with <span>ZervX</span>
            </h2> **/}
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}
