const navLinks = {
  HOME: "/",
  LOG_IN: "/login",
  RIDE_BOOKING: "/ridebooking",
  RIDE_REVIEW: "/ridereview",
  RIDE_CONFIRMED: "/rideconfirmed",
  YOUR_RIDES: "/yourrides",
  YOUR_RIDE_DEATILS: "/yourridedeatils",
  YOUR_RIDE_VIEW_RECEIPT: "/viewreceipt",
  YOUR_RIDE_VIEW_UCOMING_RIDES: "/viewupcomingrides",
  SEARCHING_PROFESSIONALS: "/searchingProfessionals",
  SCHEDULE_RIDE_BOOKING: "/scheduleridebooking",
  START_RIDE: "/startride",
  CARD_DEATILS: "/CardDeatils",
  PROFILE: "/profile",
  WALLET: "/wallet",
  REGISTRATION: "/registration",
  REWARDS: "/rewards",
  ARRIVED: "/arrived",
};
module.exports = navLinks;
