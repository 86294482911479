import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../../utils/navLinks";
import axios from "axios";
import { useParams } from "react-router";
import { format } from "date-fns";
import Countdown from "react-countdown";

import {
  FiThumbsDown,
  FiKey,
  FiPhone,
  FiStar,
  FiPercent,
  FiLifeBuoy,
  FiTriangle,
} from "react-icons/fi";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import A from "../../utils/urls.utils";
import useAdmin from "../hooks/useAdmin";
import { RideDeatilscontext } from "../context/RideDeatils";
import { useNavigate } from "react-router-dom";
import {
  CONST_EXPIRED,
  CONST_INSTANT,
  CONST_MILES_VALUE,
} from "../../utils/constantUtils";
import Notification from "./Notification/index";
import NavBar from "../navBar/NavBar";
import { SmallLoader } from "../common/SmallLoader";
import { Shimmer } from "react-shimmer";

export default function ScheduleRideBooking() {
  const { rideDeatilsData, setRideDeatilsData } =
    useContext(RideDeatilscontext);

  const { settings } = useSettings();
  const { language } = useLanguage();
  const { header, user } = useAdmin();
  const { bookingId } = useParams();
  const navigate = useNavigate();
  const retryInterval = useRef();
  const progressBarInterval = useRef();

  const [bookingDeatils, setBookingDeatils] = useState();
  const [bookingDate, setBookingDate] = useState();
  const [bookingTime, setBookingTime] = useState();
  const [searchingProfessional, setSearchingProfessional] = useState(false);
  const [rideStatus, setRideStatus] = useState("");
  const [popUpContent, setPopUpContent] = useState(true);
  const [show, setShow] = useState(false);
  const [acceptCategory, setAcceptCategory] = useState(false);
  const [oneTimeRender, setOneTimeRender] = useState(true);

  let intervalId;
  // let time;
  useEffect(() => {
    if (bookingId?.length > 10) {
      BookingDeatils();
    } else {
      navigate(NavLinks.Home);
    }
  }, [bookingId]);
  const BookingDeatils = async () => {
    if (bookingId) {
      const { data } = await axios.post(
        A.HOST + A.GET_ACCEPT_BOOKING,
        { bookingId: bookingId },
        header
      );
      //console.log("data.data");
      // console.log(data.data);
      if (data.code === 200) {
        setBookingDeatils(data.data);
        setBookingDate(format(new Date(data.data?.bookingDate), "d MMM yyy p"));
        // setBookingDate(data.data?.bookingDate);
        // alert(data.data?.bookingDate);
        // setBookingTime(format(new Date(data.data?.bookingDate), "d MMM yyy p"));
        setBookingTime(new Date(data.data?.bookingDate));
        switch (data.data.bookingStatus) {
          case "AWAITING":
            // navigate(NavLinks.RIDE_BOOKING);
            break;

          case "ACCEPTED":
            navigate(NavLinks.RIDE_CONFIRMED + "/" + bookingId);
            break;

          case "ARRIVED":
            navigate(NavLinks.ARRIVED + "/" + bookingId);
            break;

          case "STARTED":
            navigate(NavLinks.START_RIDE + "/" + bookingId);
            break;

          case "ENDED":
            if (data?.data?.user?.bookingInfo?.pendingReview) {
              navigate(
                NavLinks.RIDE_REVIEW +
                  "/" +
                  data?.data?.user?.bookingInfo.pendingReview
              );
            } else {
              navigate(NavLinks.HOME);
            }
            break;

          default:
            navigate(NavLinks.HOME);
            break;
        }
      } else {
        navigate(NavLinks.HOME);
      }
    }
  };
  const cancelRide = async (value) => {
    const data = await axios.post(
      A.HOST + A.CANCEL_BOOKING,
      {
        bookingId: bookingId,
        cancellationReason: value,
      },
      header
    );
    navigate(NavLinks.RIDE_BOOKING);
  };

  const categoryChange = async () => {
    const { data } = await axios.post(
      A.HOST + A.SCHEDULE_CATEGOEYC_CHANGE,
      { bookingId: bookingId },
      header
    );
    if (data.code === 200) {
      window.location.reload();
      // setAcceptCategory(true);
      // localStorage.setItem("AcceptCategory", JSON.stringify(true));
      // setAcceptCategory(JSON.parse(localStorage.getItem("AcceptCategory")));
    }
    console.log(data);
  };

  return (
    <main className="zervx_main">
      <Notification />

      <div className="zervx_content">
        <section className="header-outer">
          <div className="container">
            <div className="row align-items-center">
              {/* <div className="col-md-3 col-3">
                <div className="header-arrow">
                  <Link to={NavLinks.HOME}>
                    <svg
                      className="ola-svg ola-icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                      </g>
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-6">
                <div className="header-logo text-center">
                  <h5>Receipt</h5>
                </div>
              </div>
              <div className="col-md-3 col-3">
                <!-- <div className="header-login">
                        <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

                     </div> -->
              </div> */}
              <div>
                {/* <NavBar path="schedule" bookingId={bookingId} /> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Receipt_START --> */}

        <section className="receipt_main_otr">
          <div className="container">
            <div className="row">
              {bookingTime ? (
                <div className="col-lg-12 col-sm-12 relative pt-10">
                  <div className="receipt_conten border rounded-xl py-5 px-4 text-center mt-10 ">
                    <div className=" absolute top-[80px]  start-50 translate-middle bg-light px-2">
                      {settings?.mobileLogo ? (
                        <img
                          src={settings?.mobileLogo}
                          alt=""
                          className="img-fluid w-24   "
                        />
                      ) : (
                        <Shimmer
                          width={40}
                          height={40}
                          className="rounded-full "
                        />
                      )}
                    </div>
                    <div className="price_div text-start">
                      <div className="flex justify-center items-center py-2">
                        <h4>Schedule Ride</h4>
                      </div>
                      <div className=" flex justify-between  py-2">
                        <div className="text-gray-400">
                          {bookingDeatils?.vehicle.vehicleCategoryName +
                            " | " +
                            bookingDeatils?.bookingId}
                        </div>
                        <h5 className="px-2 font-bold text-green-500">
                          {bookingDeatils?.currencySymbol +
                            " " +
                            bookingDeatils?.invoice.payableAmount}
                        </h5>
                      </div>

                      <div className="py-2">
                        <span>{bookingDate}</span>
                      </div>

                      <div
                        className="flex justify-center items-center py-2 rounded-xl p-2 my-4 border"
                        // id="shadow"
                      >
                        <span className="pr-2 text-xs">{language.PICKUP_IN}</span>
                        <h5 className="text-green-400 flex items-end">
                          <Countdown
                            // date={bookingTime + 10000}
                            date={Date.parse(bookingTime) + 10000}
                            // date={new Date(bookingTime) + 10000}
                            intervalDelay={0}
                            precision={3}
                            renderer={(props) => (
                              <>
                                <div>
                                  {props.total === 0
                                    ? navigate(NavLinks.HOME)
                                    : props.hours === props.hours - 1 &&
                                      navigate(NavLinks.HOME)}
                                </div>
                                <div className=" flex ">
                                  {props.days ? (
                                    <div className="flex items-end px-1">
                                      {props.days}
                                      <div className=" text-xs px-1 text-gray-600">
                                        d
                                      </div>
                                      <span className="text-black">:</span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {props.hours ? (
                                    <div className="flex items-end px-1">
                                      {props.hours}
                                      <div className=" text-xs px-1 text-gray-600">
                                        h
                                      </div>
                                      <span className="text-black">:</span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {props.minutes ? (
                                    <div className="flex items-end px-1">
                                      {props.minutes}
                                      <div className=" text-xs px-1 text-gray-600">
                                        m
                                      </div>
                                      <span className="text-black">:</span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className=" flex items-end px-1">
                                    {props.seconds}
                                    <div className=" text-xs px-1 text-gray-600">
                                      s
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          />
                        </h5>
                        <span className="pl-2 text-xs">Mins</span>
                      </div>
                      <div className="py-2">
                        <h6 className="">
                          {bookingDeatils?.activity.rideStops[0].addressName}
                        </h6>
                      </div>
                      <div className=" text-xs py-2">
                        {language.ALERT_FARE_CHANGE}
                      </div>
                      <div className="text-xs text-green-600">
                        {language.ALERT_ACCEPT_OTHER_CATEGORY}
                      </div>
                      <div className="text-center my-4">
                        {bookingDeatils.isAcceptOtherCategory === false && (
                          <div
                            className="    text-center py-2 flex items-center justify-center"
                            onClick={() => categoryChange()}
                            role="button"
                          >
                            <span className=" bg-green-600 rounded-xl text-white p-2 text-[11px] my-auto font-bold">
                              {language.ACCEPT_OTHER_CATEGORY}
                            </span>
                          </div>
                        )}

                        <div
                          className="my-3  py-2 flex items-center justify-center"
                          onClick={() => {
                            cancelRide();
                          }}
                          role="button"
                        >
                          <span className=" bg-red-600 rounded-xl text-white  text-center p-2 text-xs font-bold">
                            {language.CANCEL_RIDES}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <SmallLoader />
              )}
            </div>
          </div>
        </section>
      </div>
      <div
        className="zervx_rightimg"
        // style="background-image: url('images/bg_city.jpg');"
      >
        <img
          src={settings?.bookingAppImage}
          alt=""
          className="w-screen h-screen"
        />

        <div className="global-img-right">
          {/* <h2>
            Ride with <span>Comfort</span>
          </h2>
          <h2>
            Ride with <span>ZervX</span>
          </h2> */}
          <h2>{settings?.bookingAppHomePageMessage}</h2>
        </div>
      </div>
    </main>
  );
}
