import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../../utils/navLinks";
import axios from "axios";
import { useParams } from "react-router";
import { format } from "date-fns";

import {
  FiThumbsDown,
  FiKey,
  FiPhone,
  FiStar,
  FiPercent,
  FiLifeBuoy,
  FiTriangle,
} from "react-icons/fi";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import A from "../../utils/urls.utils";
import useAdmin from "../hooks/useAdmin";
import barcode from "../../assets/images/barcode.png";
import { SmallLoader } from "../common/SmallLoader";

export default function ViewReceipt() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { header, user } = useAdmin();
  const { bookingId } = useParams();

  const [bookingDeatils, setBookingDeatils] = useState();

  // const [bookingDeatils, setBookingDeatils] = useState({
  //   tipsAmount: 0,
  //   serviceTax: 0,
  //   surchargeTitle: "",
  //   surchargeFee: 0,
  //   couponApplied: false,
  //   couponAmount: 0,
  //   professionalCommision: 0,

  //   pickUp: null,
  //   drop: null,
  //   firstName: "",
  //   lastName: "",
  //   bookingID: "",
  //   bookingOTP: "",
  //   avatar: "",
  //   plateNumber: "",
  //   model: "",
  //   color: "",
  //   vehicleCategory: "",
  //   avgRating: "",
  //   categoryImage: "",
  //   pickUpLat: 0,
  //   pickUpLng: 0,
  //   dropLat: "",
  //   dropLng: "",
  //   bookingDate: "",
  //   amount: 0,
  //   currencySymbol: "",
  //   professionalImg: "",
  //   professionalRating: [],
  // });

  const getBookingDetailById = async () => {
    try {
      // alert(JSON.stringify("bookingId" + bookingId));

      const { data } = await axios.post(
        A.HOST + A.VIEW_RECEIPT,
        { bookingId: bookingId },
        header
      );
      setBookingDeatils(data.data);
      console.log(data.data);
      //   setBookedInformation(data.data);
      // setBookingDeatils({
      //   tipsAmount: data.data.invoice.tipsAmount,
      //   serviceTax: data.data.invoice.serviceTax,
      //   surchargeTitle: data.data.invoice.surchargeTitle,
      //   surchargeFee: data.data.invoice.surchargeFee,
      //   couponApplied: data.data.invoice.couponApplied,
      //   couponAmount: data.data.invoice.couponAmount,
      //   professionalCommision: data.data.invoice.professionalCommision,

      //   pickUp: data.data.destination.addressName,
      //   drop: data.data.origin.addressName,
      //   firstName: data.data.professional.firstName,
      //   lastName: data.data.professional.lastName,
      //   bookingID: data.data.bookingId,
      //   bookingOTP: data.data.bookingOTP,
      //   avatar: data.data.professional.avatar,
      //   plateNumber: data.data.vehicle.plateNumber,
      //   bookingType: data.data.bookingType,
      //   dropLat: data.data.destination.lat,
      //   dropLng: data.data.destination.lng,
      //   pickUpLat: data.data.origin.lat,
      //   pickUpLng: data.data.origin.lng,
      //   vehicleCategory: data.data.vehicle.vehicleCategory.vehicleCategory,
      //   model: data.data.vehicle.model,
      //   categoryImage: data.data.vehicle.vehicleCategory.categoryImage,
      //   bookingDate: format(new Date(data.data.bookingDate), "d MMM yyy p"),
      //   amount: data.data.invoice.payableAmount,
      //   currencySymbol: data.data.currencySymbol,
      //   professionalImg: data.data.professional.avatar,
      //   professionalRating: [data.data.professional.review.avgRating],
      // });
      //   alert(data.data.professional.review.avgRating);
      // alert(JSON.stringify(data.data.destination.addressName));
      console.log(data.data);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    getBookingDetailById();
  }, [bookingId]);
  return (
    <>
      <main class="zervx_main">
        <div class="zervx_content">
          <section class="header-outer">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-3 col-3">
                  <div class="header-arrow">
                    <Link to={NavLinks.YOUR_RIDE_DEATILS + "/" + bookingId}>
                      <svg
                        className="ola-svg ola-icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="header-logo text-center">
                    <h5>{language.RECEIPT}</h5>
                  </div>
                </div>
                <div class="col-md-3 col-3"></div>
              </div>
            </div>
          </section>

          <section class="receipt_main_otr">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  {bookingDeatils ? (
                    <div class="receipt_content">
                      <div class="logo_div">
                        <img
                          src={settings?.mobileLogo}
                          alt=""
                          
                        />
                      </div>
                      <div class="price_div">
                        {bookingDeatils?.fareSummary.map((each) => (
                          <h2>
                            {each.key === "totalBill"
                              ? each.currencySymbol + " " + each.value
                              : null}
                          </h2>
                        ))}
                      </div>
                      <div class="cash_outer">
                        <div class="cash_title">
                          {bookingDeatils?.bookingId}
                        </div>
                        <div class="cash_title">{language.CASH}</div>
                      </div>
                      <div class="table_otr py-4">
                        <table>
                          <tbody>
                            {bookingDeatils?.fareSummary.map((each) => (
                              <tr className="">
                                <td>{each.title}</td>
                                <td>
                                  {each.currencySymbol + " " + each.value}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      
                      <div class="date_time">{format(
                                    new Date(bookingDeatils.bookingDate),
                                    "d MMM yyy p"
                                  )}</div>

                      {/* <div class="barcode_div">
                        <img src={barcode} alt="" title="" />
                      </div> */}
                    </div>
                  ) : (
                    <div class="receipt_conten animate-pulse rounded-xl  space-x-4 h-[40rem] bg-slate-300 mx-10"></div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="zervx_rightimg">
          <img
            src={settings?.bookingAppImage}
            alt=""
            className="w-screen h-screen"
          />

          <div class="global-img-right">
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}
