import React, { createContext, useState, useEffect } from "react";

export const RideDeatilscontext = createContext();

function RideDeatilsProvider({ children }) {
  // const [getdata,setgetdata]=useState()
  // const [loading,setloading]=useState()
  const [rideDeatilsData, setRideDeatilsData] = useState({});

  return (
    <RideDeatilscontext.Provider
      value={{ rideDeatilsData, setRideDeatilsData }}
    >
      {children}
    </RideDeatilscontext.Provider>
  );
}

export default RideDeatilsProvider;
