import { atom, useRecoilState } from "recoil";
import axios from "axios";
import LanguageJSON from "../../utils/language.json";
import API from "../../utils/urls.utils";

const languageAtom = atom({
  key: "languageAtom",
  // default: null,
  default: LanguageJSON,
});

export default function useLanguage() {
  const [language, setLanguage] = useRecoilState(languageAtom);

  const fetchLanguage = async (lang) => {
    try {
      const { data } = await axios.post(API.HOST + API.LANGUAGE_KEYS, {
        languageCode: lang,
      });
      if (data.languageKeys) setLanguage(data.languageKeys);
      return data.languageKeys || language;
    } catch (err) {}
  };

  return { language, setLanguage, fetchLanguage };
}
