import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../../utils/navLinks";
import axios from "axios";
import { useParams } from "react-router";
import { format } from "date-fns";

import {
  FiThumbsDown,
  FiKey,
  FiPhone,
  FiStar,
  FiPercent,
  FiLifeBuoy,
  FiTriangle,
} from "react-icons/fi";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import A from "../../utils/urls.utils";
import useAdmin from "../hooks/useAdmin";
import { SmallLoader } from "../common/SmallLoader";

export default function YourRides() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { header, user } = useAdmin();
  const { bookingId } = useParams();
  const [rideHistoryData, setRideHistoryData] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const rideHistory = async () => {
      try {
        const { data } = await axios.post(
          A.HOST + A.GET_BOOKING_HISTORY,
          { skip: 0, limit: 30 },
          header
        );
        if (data) {
          setRideHistoryData(data.data);
          setLoading(true);
        } else {
          setRideHistoryData([]);
          setLoading(true);
        }
        //console.log(data.data);

        // alert("rideHistoryData" + JSON.stringify(rideHistoryData));
        // data.data.map((each) => {
        //   alert(JSON.stringify(each.bI));
        // });
      } catch (err) {
        alert(err);
      }
    };
    rideHistory();
  }, []);

  //   useEffect(() => {
  //     console.log(rideHistoryData);
  //   }, [rideHistoryData]);

  return (
    <>
      {/* <SmallLoader /> */}
      <main className="zervx_main">
        <div className="zervx_content">
          <section className="header-outer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3 col-3">
                  <div className="header-arrow">
                    <Link to={NavLinks.HOME}>
                      <svg
                        className="ola-svg ola-icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-6">
                  <div className="header-logo text-center">
                    <h5>{language.YOUR_RIDES}</h5>
                  </div>
                </div>
                <div className="col-md-3 col-3">
                  {/* <!-- <div className="header-login">
                        <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

                     </div> --> */}
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Location_main_outer --> */}
          {/* {alert(JSON.stringify(rideHistoryData))} */}
          {!loading ? (
            <SmallLoader />
          ) : rideHistoryData.length > 0 ? (
            <section className="your-rides-outer">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="your-rides-inner">
                      {rideHistoryData.map((bookingHistory, index) => (
                        <Link
                          to={
                            NavLinks.YOUR_RIDE_DEATILS +
                            "/" +
                            bookingHistory._id
                          }
                          className={" hover:decoration-0 hover:outline-none"}
                        >
                          <div
                            className="  your-rides-box rounded-xl my-2 shadow-xl"
                            key={index + 1}
                          >
                            <div className="col-md-12 flex px-0">
                              <div className="icon-div col-md-2 col-2 flex items-center">
                                {/* <img src="images/ic_auto.png" /> */}
                                <img
                                  src={
                                    bookingHistory.vehicle.vehicleCategoryImage
                                  }
                                  alt=""
                                  className="user_icon"
                                />
                              </div>
                              <div className="your-rides-content col-md-7 col-7">
                                <div className="your-rides-name">
                                  {/* {bookingHistory.bD} */}
                                  {format(
                                    new Date(bookingHistory.bookingDate),
                                    "d MMM yyy p"
                                  )}
                                </div>
                                <div className="desc">
                                  {bookingHistory.vehicle.vehicleCategoryName +
                                    " " +
                                    bookingHistory.bookingId}
                                </div>

                                <ul>
                                  <li className="start_point">
                                    {
                                      bookingHistory.activity.rideStops[0]
                                        .addressName
                                    }
                                  </li>
                                  {/* <li className="end_point">
                                    Gundy, Chennai, Tamil Nadu, Gundy, Chennai,
                                    Tamil Nadu, Tamil Nadu...
                                  </li> */}
                                  <li
                                    className={` ${
                                      bookingHistory.bookingStatus === "ENDED"
                                        ? " border-green-600 bg-green-600"
                                        : "border-red-600 bg-red-600"
                                    } border-2 text-center font-bold text-white rounded-full p-2 `}
                                  >
                                    {bookingHistory.bookingStatus}
                                  </li>
                                </ul>
                              </div>
                              <div className="  col-md-3  col-3 px-0">
                                <div className=" text-center text-black">
                                  {bookingHistory.currencySymbol +
                                    " " +
                                    bookingHistory.invoice.payableAmount}
                                </div>
                                <div className=" flex justify-center ">
                                  {/* <img src="images/profile.png" /> */}
                                  <img
                                    src={bookingHistory.user.avatar}
                                    alt=""
                                    className=" p-1 rounded-full w-20 h-20 border"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                      {/* {!!rideHistoryData && <SmallLoader />} */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            rideHistoryData.length === 0 && (
              <div className="text-center p-5">
                <h6>{language.NO_RIDES}</h6>
                <p className="text-sm px-5">{language.INFO_RIDES_HISTORY}</p>
              </div>
            )
          )}
        </div>
        <div
          className="zervx_rightimg"
          // style="background-image: url('images/bg_city.jpg');"
        >
          <img
            src={settings?.bookingAppImage}
            alt=""
            className="w-screen h-screen"
          />

          <img src="" alt="" />
          <div className="global-img-right">
            {/* <h2>
                Ride with <span>Comfort</span>
              </h2>
              <h2>
                Ride with <span>ZervX</span>
              </h2> */}
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}
