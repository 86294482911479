import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import A from "../../utils/urls.utils";
// import useAdmin from "../hooks/useAdmin";
import useSettings from "./useSettings";
import Cookies from "js-cookie";
// import useAdmin from "./useAdmin";

export default function useSocket() {
  const adminSocket = useRef(null);
  const notificationSocket = useRef(null);
  const { settings } = useSettings();

  const [firstRun, setFirstRun] = useState(true);
  // const { token } = useAdmin();
  let accesToken =
    Cookies.get("CBU")?.substring(13) ||
    JSON.parse(localStorage.getItem("CBU"));

  const connectSocket = () => {
    // if (accesToken !== null && firstRun) {
    notificationSocket.current = io(A.SOCKET, {
      forceNew: true,
      path: "/socket.io",
      reconnection: true,
      reconnectionDelay: 2000, //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
      reconnectionDelayMax: 60000, //1 minute maximum delay between connections
      reconnectionAttempts: "Infinity", //to prevent dead clients, having the user to having to manually reconnect after a server restart.
      timeout: 10000,
      autoconnect: true,
      // transports: [
      //   settings?.isSocketPolling === false ? "websocket" : "polling",
      // ],
      transports: ["websocket"],
      query: {
        accessToken: `${accesToken}`,
        deviceId: accesToken,
        // "TkRjMU9UVTNZVEJrT0RNeE5XUmtNRGt6TWpnek5tUTVZakExWWpoa05ETm1aak5rT0RNMFlXSTVObU01WmprNFpUWmhNMkl4TXpCak1XUmhOMlF6WVE6ZXlKZmFXUWlPaUkyTXpGaFpEWXhabU5oTnpGbE16WTBPRGxsTm1VeFl6UWlMQ0owZVhCbElqb2lWVk5GVWlJc0ltUmxkbWxqWlZSNWNHVWlPaUpEYUhKdmJXVXZNVEV4TGpBdU1DNHdJaXdpY0d4aGRHWnZjbTBpT2lKTllXTWdUMU12ZFc1a1pXWnBibVZrSWl3aVkzSmxZWFJsWkVGMElqb2lNakF5TXkwd05DMHdOVlF3TmpvME1qb3lNQzQ1TXpOYUluMA",
      },
    });
    // notificationSocket.current.on("disconnect", (err) => {
    //   console.log("Connection Error !!! " + err);
    //   notificationSocket.current.disconnect();
    //   console.log("Connection Error !!! " + err);
    // });
    notificationSocket.current.on("connect", () => {
      console.log("User Socket Connected !!! ");
      // notificationSocket.current.connect();
      console.log();
      // notificationSocket.current.emit(
      //   "getNearbyDrivers",
      //   // { userId: "607e8bf0388aa52aa0b2e358" },
      //   { lat: "8.307531", lng: "77.221832" },
      //   (data) => {
      //     // alert(JSON.stringify(data));
      //     console.log(data);
      //   }
      // );
      // notificationSocket.current.on(
      //   "getNearbyDrivers",
      //   // { userId: "607e8bf0388aa52aa0b2e358" },

      //   (data) => {
      //     // alert(JSON.stringify(data));
      //     console.log(data);
      //   }
      // );
      // notificationSocket.current.emit(
      //   "trackBookingDetailsByIdFromWebBookingApp",
      //   { bookingId: "607e8bf0388aa52aa0b2e358" },
      //   (data) => {
      //     console.log(data);
      //   }
      // );
      // notificationSocket.current.on(
      //   "trackBookingDetailsByIdFromWebBookingApp",
      //   (data) => {
      //     console.log(data);
      //   }
      // );
    });

    // notificationSocket.current.on("disconnection", () => connectSocket());
    setFirstRun(false);
  };
  // };
  // useEffect(() => {
  //   console.log("notificationSocket");
  //   console.log(notificationSocket);
  //   if (notificationSocket.connected) {
  //     console.log("notificationSocket_______");

  //     notificationSocket.current.on(
  //       "trackBookingStatusForWebBookingApp",
  //       (data) => {
  //         console.log(JSON.stringify(data));
  //       }
  //     );
  //   }
  // }, [notificationSocket]);
  useEffect(() => {
    // setTimeout(() => {
    connectSocket();
    // }, 10000);
  }, []);

  return {
    adminSocket,
    notificationSocket,
  };
}
