import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import U from "../../utils/urls.utils";
// let firebaseConfig = {};
import useSettings from "../hooks/useSettings";

// import useFirebase from "./useFireBase";
// TODO: Replace the following with your app's Firebase project configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyB0OF6Llm7yBYWQRYFij5EBQmhnDxuMbWc",
//   authDomain: "zervx-7b622.firebaseapp.com",
//   databaseURL: "https://zervx-7b622-default-rtdb.firebaseio.com",
//   projectId: "zervx-7b622",
//   storageBucket: "zervx-7b622.appspot.com",
//   messagingSenderId: "469093796333",
//   appId: "1:469093796333:web:71687a0e0684811d99bfe4",
//   measurementId: "G-XTCC9ETJZX",

// apiKey: "AIzaSyA7snpaA-_XoHgGoxGqa6-lKuLTiXBSt-M",
// authDomain: "cabzone-f1eb4.firebaseapp.com",
// databaseURL: "https://cabzone-f1eb4-default-rtdb.firebaseio.com",
// projectId: "cabzone-f1eb4",
// storageBucket: "cabzone-f1eb4.appspot.com",
// messagingSenderId: "784985601341",
// appId: "1:784985601341:web:c8297b8c44a03cfe904154",
// measurementId: "G-4L69457300",
// };
// switch (U.mode) {
//   // ZayRide Configuration
//   case "zayride":
//   case "zayridetesting":
//     // firebaseConfig = {
//     //   apiKey: "AIzaSyDEEctsvjqDG3alV1X0UwRCFQL0ZCH_0I8",
//     //   authDomain: "zay-trak.firebaseapp.com",
//     //   databaseURL: "https://zay-trak.firebaseio.com",
//     //   projectId: "zay-trak",
//     //   storageBucket: "zay-trak.appspot.com",
//     //   messagingSenderId: "134452006434",
//     //   appId: "1:134452006434:web:ab885a725203cc4edb447b",
//     //   measurementId: "G-2Z1DW3FPVD",
//     // };
//     break;

//   // Pamworld
//   case "pamworld":
//     // firebaseConfig = {
//     //   apiKey: "AIzaSyBto5Xcir9gVwB6RzUglyWP1kh0XlS5HYE",
//     //   authDomain: "pamdrive-drivers-209406.firebaseapp.com",
//     //   databaseURL: "https://pamdrive-drivers-209406.firebaseio.com",
//     //   projectId: "pamdrive-drivers-209406",
//     //   storageBucket: "pamdrive-drivers-209406.appspot.com",
//     //   messagingSenderId: "196730039656",
//     //   appId: "1:196730039656:web:364be9d94dc3c4e648fadd",
//     // };

//     break;

//   // StarMov
//   case "starmov":
//     // firebaseConfig = {
//     //   apiKey: "AIzaSyB8kRNsWTnH6ihRn_DgbVrIJmfciZtWA94",
//     //   authDomain: "starmov-1c215.firebaseapp.com",
//     //   databaseURL: "https://starmov-1c215-default-rtdb.firebaseio.com",
//     //   projectId: "starmov-1c215",
//     //   storageBucket: "starmov-1c215.appspot.com",
//     //   messagingSenderId: "929584710641",
//     //   appId: "1:929584710641:web:132a4cb9ab5973ba9cfa50",
//     //   measurementId: "G-CX6E2JBR28",
//     // };
//     break;

//   case "jetride":
//   case "jetridenew":
//     // jetride

//     // firebaseConfig = {
//     //   apiKey: "AIzaSyAa0Ziqifp4ZBRGYm2t3mofgbZHOGvHaPY",
//     //   authDomain: "jetrideapp-342712.firebaseapp.com",
//     //   databaseURL: "https://jetrideapp-342712-default-rtdb.firebaseio.com",
//     //   projectId: "jetrideapp-342712",
//     //   storageBucket: "jetrideapp-342712.appspot.com",
//     //   messagingSenderId: "753083911778",
//     //   appId: "1:753083911778:web:4e72441bd8a0f0d1d04b3b",
//     //   measurementId: "G-4HL45SF7WZ",
//     // };
//     break;

//   // digito
//   case "digito":
//     // firebaseConfig = {
//     //   apiKey: "AIzaSyAnV5fVEougPT-wIqrlDUIklvDKd3GiTZE",
//     //   authDomain: "digitoapp-d6a50.firebaseapp.com",
//     //   databaseURL:
//     //     "https://digitoapp-d6a50-default-rtdb.asia-southeast1.firebasedatabase.app",
//     //   projectId: "digitoapp-d6a50",
//     //   storageBucket: "digitoapp-d6a50.appspot.com",
//     //   messagingSenderId: "79562806477",
//     //   appId: "1:79562806477:web:4b51dc75a51f9157534584",
//     //   measurementId: "G-T7CZ73CDCK",
//     // };
//     break;

//   // twende
//   case "twende":
//   case "twendetesting":
//     // firebaseConfig = {
//     //   apiKey: "AIzaSyDbBLAnfhvqDB4hVzTv0E2ZT9Fxn3CXBsw",
//     //   authDomain: "twendetech-b877e.firebaseapp.com",
//     //   databaseURL: "https://twendetech-b877e-default-rtdb.firebaseio.com",
//     //   projectId: "twendetech-b877e",
//     //   storageBucket: "twendetech-b877e.appspot.com",
//     //   messagingSenderId: "161021641115",
//     //   appId: "1:161021641115:web:72600b957b9a283ddb613e",
//     //   measurementId: "G-V6ZCES8ZYD",
//     // };
//     break;

//   // cabzone
//   case "cabzone":
//     firebaseConfig = {
//       apiKey: "AIzaSyA7snpaA-_XoHgGoxGqa6-lKuLTiXBSt-M",
//       authDomain: "cabzone-f1eb4.firebaseapp.com",
//       databaseURL: "https://cabzone-f1eb4-default-rtdb.firebaseio.com",
//       projectId: "cabzone-f1eb4",
//       storageBucket: "cabzone-f1eb4.appspot.com",
//       messagingSenderId: "784985601341",
//       appId: "1:784985601341:web:c8297b8c44a03cfe904154",
//       measurementId: "G-4L69457300",
//     };
//     break;

//   default:
//     // Zervx Configuration
//     firebaseConfig = {
//       apiKey: "AIzaSyB0OF6Llm7yBYWQRYFij5EBQmhnDxuMbWc",
//       authDomain: "zervx-7b622.firebaseapp.com",
//       databaseURL: "https://zervx-7b622-default-rtdb.firebaseio.com",
//       projectId: "zervx-7b622",
//       storageBucket: "zervx-7b622.appspot.com",
//       messagingSenderId: "469093796333",
//       appId: "1:469093796333:web:71687a0e0684811d99bfe4",
//       measurementId: "G-XTCC9ETJZX",
//     };

//     break;
// }

// const app = initializeApp(firebaseConfig);
// export const authentication = getAuth(app);
export default function useFirebase() {
  const { settings, fetchSettings } = useSettings();

  // const app = initializeApp(settings?.firebaseConfig);
  // const authentication = getAuth(app);
  // if (!firebase.apps.length) {
  //   firebase.initializeApp(settings.firebaseConfig);
  // } else {
  //   firebase.app();
  // }
  // alert(JSON.stringify(settings));
  let authentication;
  if (settings === null) {
    fetchSettings();
    // console.log(authentication);
  } else {
    // useEffect(() => {
    if (settings?.firebaseConfig !== null) {
      const app = initializeApp(settings?.firebaseConfig);
      authentication = getAuth(app);
    } else {
      authentication = initializeApp.app();
    }
    // console.log(authentication);
  }
  // }, [settings]);

  // const db = firebase.firestore();
  return {
    authentication,
  };
}
