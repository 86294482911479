import React from "react";

export function FieldWrapper({
  title,
  placeholder,
  animate,
  icon,
  type,
  change = () => {},
  hint,
  children,
  style = {},
  padding = true,
  error = false,
  flex = true,
  innerStyle = {},
  margin = true,
  className,
  ...rest
}) {
  // if (style?.width) {
  //   alert("yes");
  // }
  return (
    <div
      className={`${margin === true ? "mb-2 " : "my-1"} ${className} ${
        animate && "opacity-0 " + animate
      }`}
      style={{ ...style }}
      {...rest}
    >
      <div className={`flex flex-col ${padding === true && "px-2"} `}>
        {title && (
          <div className={`flex items-center px-1 mb-2 `}>
            <p
              className={`dark:text-gray-200 text-gray-500 text-sm  ${
                error != "" && "text-red-500 dark:text-red-500 "
              }`}
            >
              {title}
            </p>
          </div>
        )}
        <div
          style={innerStyle}
          className={`relative ${flex === true && "flex"}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
