import React, { useEffect, useState } from "react";
import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import { Link, useNavigate } from "react-router-dom";
import navLinks from "../../utils/navLinks";
import { Button } from "react-bootstrap";

function Rewards() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const [rewardData, setRewardData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setRewardData(JSON.parse(localStorage.getItem("Rewards")));
    console.log(JSON.parse(localStorage.getItem("Rewards")));
  }, []);

  return (
    <>
      <main className="zervx_main">
        <div className="zervx_content">
          {/* <div className=" relative top-0 ">
                <NavBar path="review" bookingId={bookingId} />
              </div> */}
          <section className="header-outer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3 col-3">
                  <div className="header-arrow">
                    <Link
                      to={navLinks.RIDE_REVIEW + "/" + rewardData?.bookingId}
                    >
                      <svg
                        className="ola-svg ola-icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-6">
                  <div className="header-logo text-center">
                    {/* <h5>Thank You</h5> */}
                  </div>
                </div>
                <div className="col-md-3 col-3">
                  {/* <!-- <div className="header-login">
                        <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

                     </div> --> */}
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Review_section_start --> */}

          <section className="review_main_otr overflow-scroll">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="review_content">
                    <h4>{language.CONGRATULATION}</h4>
                    <div className="">
                      <img
                        src="http://52.66.154.128/assets/images/rewards/img_hero_rewards.png"
                        alt=""
                      />
                    </div>
                    <div className="py-3">
                      <h6>{language.HI + ", " + rewardData?.user}</h6>
                      <p>{language.INFO_REWARD_WELCOME}</p>
                      <div className="flex justify-center font-bold text-2xl ">
                        <p className="px-2 text-green-600">
                          {rewardData?.points}
                        </p>
                        <p className="text-green-600">
                          {rewardData?.rewardType}
                        </p>
                      </div>
                    </div>
                    {/* <div className="bg-green-300 rounded-xl">
                      <p className="text-black p-2">
                        You can withdraw your points converted to welfare
                      </p>
                    </div> */}
                    <div className="">
                      {/* <Button className="btn btn primary rounded-full mt-3">
                        View All Rewards
                      </Button> */}
                      <p
                        className="mt-4 cursor-pointer"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        {language.SKIP}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="zervx_rightimg">
          <img
            src={settings?.bookingAppImage}
            alt=""
            className="w-screen h-screen"
          />

          <div className="global-img-right">
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}

export default Rewards;
