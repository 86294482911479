import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../../utils/navLinks";
import axios from "axios";
import { useParams } from "react-router";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  FaUser,
  FaIdCard,
  FaPlus,
  FaAngleRight,
  //   FaIdCard,
} from "react-icons/fa";
import { RxCalendar } from "react-icons/rx";
import { MdVpnKey } from "react-icons/md";
import Modals from "react-bootstrap/Modal";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import A from "../../utils/urls.utils";
import useAdmin from "../hooks/useAdmin";

import cardImg from "../../assets/images/pngtree-debit-card-bank-card-png-image_2855172.jpeg";
import { TextField } from "../common/TextFeild";
import { Button } from "react-bootstrap";
import { async } from "@firebase/util";
import { useFormik } from "formik";
import * as yup from "yup";
import { CONST_USER } from "../../utils/constantUtils";
import { CardDeatilscontext } from "../context/CardDeatilsContext";

export default function Registration() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { header } = useAdmin();
  const { viewBy } = useParams();
  const navigate = useNavigate();

  const { CardDeatilsData, setCardDeatilsData } =
    useContext(CardDeatilscontext);

  const [popUpContent, setPopUpContent] = useState(true);

  const [user, setUser] = useState();
  const [cardVerifyData, setCardVerifyData] = useState();
  const [isAddCard, setIsAddCard] = useState(false);
  const [cardList, setCardList] = useState();
  const [show, setShow] = useState(false);

  const general = {
    cardNumber: "",
    holderName: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: "",
  };

  const formik = useFormik({
    initialValues: general,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      cardNumber: yup.string().required(language.REQUIRED),
      holderName: yup.string().required(language.REQUIRED),
      //   expiry: yup.string().required(language.REQUIRED),
      expiryMonth: yup.string().required(language.REQUIRED),
      expiryYear: yup.string().required(language.REQUIRED),
      cvv: yup.string().required(language.REQUIRED),
    }),
    onSubmit: async (e) => {
      // alert("card Verify");
      const payment = await axios.post(
        A.HOST + A.VERIFY_CARD_PAYMENT_GATWAY,
        {
          userType: CONST_USER,
          userId: user.userId,
          cardNumber: e.cardNumber,
          paymentGatway: user.paymentGateWay.gateWay,
          //   card: {
          //   cardNumber: e.cardNumber,
          holderName: e.holderName,
          expiryMonth: e.expiryMonth,
          expiryYear: e.expiryYear,
          cvv: e.cvv,
          //   },
        }, //userId
        header
      );
      console.log("payment");
      console.log(payment);
      // if(payment?.data?.code === 200){
        setCardVerifyData(payment.data);
        setShow(true);
        setPopUpContent(payment.message);
      // } else{
      //   setShow(true);
      //   setPopUpContent(payment.data.message);
      //   return;
      // }
      //   alert(JSON.stringify(payment.data.data.transactionId));
      //   return;
      if (payment) {
        try {
          // alert("card add start");

          const { data } = await axios.post(
            A.HOST + A.ADD_CARD_DEATILS,
            {
              cardNumber: e.cardNumber,
              holderName: e.holderName,
              expiryMonth: e.expiryMonth,
              expiryYear: e.expiryYear,
              cvv: e.cvv,
              transactionId: cardVerifyData?.data.transactionId,
              paymentGateWayCustomerId:
                cardVerifyData?.data.paymentGateWayCustomerId,
              userId: user?.userId,
              userType: CONST_USER,
              pin: e.cvv,
            },
            header
          );
          console.log(data);
          setShow(true);
          setPopUpContent("Card Added");
          // alert("card add success");

          window.location.reload(true);
        } catch (err) {
          alert("card add err");

          alert(err);
        }
      }
    },
  });
  return (
    <>
      <Modals show={show} onHide={show}>
        {/* <Modals.Header> */}
        <Modals.Title className="text-center">{popUpContent}</Modals.Title>
        {/* </Modals.Header> */}
      </Modals>
      <main class="zervx_main">
        <div class="zervx_content">
          <section class="header-outer">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-3 col-3  ">
                  <div class="header-arrow">
                    <Link
                      to={
                        viewBy === "booking"
                          ? NavLinks.RIDE_BOOKING
                          : NavLinks.PROFILE
                      }
                    >
                      <svg
                        class="ola-svg ola-icon  top-0"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div class="col-md-6 col-6 ">
                  <div class="header-logo text-center ">
                    <h5>{language.CARD_DETAILS}</h5>
                  </div>
                </div>
                <div class="col-md-3 col-3"></div>
              </div>
            </div>
          </section>

          {/* <!-- Receipt_START --> */}

          <section class="receipt_main_otr">
            <div class="container">
              <div class="row">
                {cardList && (
                  <div>
                    {cardList.map((each) => (
                      <div
                        className="flex justify-center cursor-pointer my-2"
                        onClick={() => {
                          // localStorage.setItem(
                          //   "CardDeatils",
                          //   JSON.stringify({
                          //     id: each._id,
                          //     cardNumber: each.cardNumber,
                          //     expiryMonth: each.expiryMonth,
                          //     expiryYear: each.expiryYear,
                          //     cvv: each.cvv,
                          //     // each,
                          //   })
                          // );
                          setCardDeatilsData(each);
                          // alert(JSON.stringify(each));

                          //   navigate(NavLinks.RIDE_BOOKING);
                        }}
                      >
                        <div className=" bg-gray-300 p-2 flex  justify-evenly col-lg-6 col-sm-12 rounded-xl">
                          <div className="flex items-center">
                            <FaIdCard />
                          </div>
                          <div>
                            {"XXXXXXXXXXX" + each.cardNumber.substr(13, 17)}
                          </div>
                          <div>{each.expiryMonth + "/" + each.expiryYear}</div>
                          <span
                            className={`${
                              CardDeatilsData?._id === each._id
                                ? "bg-black"
                                : "bg-white"
                            }  p-1 h-1 rounded-full`}
                          ></span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div
                  className="flex justify-evenly my-3 cursor-pointer"
                  onClick={(e) => {
                    if (isAddCard === false) {
                      setIsAddCard(true);
                    } else {
                      setIsAddCard(false);
                    }
                  }}
                >
                  <div className="bg-blue-100  p-2 flex  justify-evenly col-lg-6 col-sm-12 rounded-xl ">
                    <div className="  flex items-center px-2">
                      <FaPlus className="text-blue-400" />
                    </div>
                    <div className="grid grid-rows-2">
                      <span className=" text-start font-semibold text-gray-400">
                        {language.TAP_HERE}
                      </span>
                      <div className="text-start">
                        <span>{language.INFO_ADD_CARD_DETAILS}</span>
                      </div>
                    </div>
                    <div className="flex items-center  text-end ">
                      <div className=" p-2 shadow-md bg-white rounded-full">
                        <FaAngleRight className="" />
                      </div>
                    </div>
                  </div>
                </div>
                {isAddCard ? (
                  <div class="col-lg-12 col-sm-12">
                    {/* <div class="receipt_content">
                <div class="logo_div">
                  <img src="images/zervxpng.png" alt="" title="" />
                </div>
                <div class="price_div">
                  <h2> $5.00</h2>
                </div>
                <div class="cash_outer">
                  <div class="cash_title">ZH-809978458734</div>
                  <div class="cash_title">Cash</div>
                </div>
                <div class="table_otr">
                  <table>
                    <tbody>
                      <tr>
                        <td>Trip Fee</td>
                        <td>$50</td>
                      </tr>
                      <tr>
                        <td>Services Tax</td>
                        <td>$0.00</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total Bill</td>
                        <td>$50.00</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="date_time">11/12/2020, 09:04</div>

                <div class="barcode_div">
                  <img src="images/barcode.png" alt="" title="" />
                </div>
              </div> */}
                    <div className=" bg-slate-400 w-full h-60 rounded-xl ">
                      {/* <img src={cardImg} alt="" /> */}
                    </div>
                    <div className="pt-2">
                      <h5>{language.BASIC_DETAILS}</h5>
                    </div>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2 py-2">
                      <div>
                        <TextField
                          placeholder={language.CARD_NUMBER}
                          icon={<FaIdCard />}
                          value={formik.values.cardNumber}
                          change={(e) => {
                            formik.setFieldValue("cardNumber", e);
                          }}
                          error={formik.errors.cardNumber}
                        />
                      </div>
                      <div className="">
                        <TextField
                          placeholder={language.CARD_HOLDER_NAME}
                          icon={<FaUser />}
                          value={formik.values.holderName}
                          change={(e) => {
                            formik.setFieldValue("holderName", e);
                          }}
                          error={formik.errors.holderName}
                        />
                      </div>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2 py-2">
                      <div className="flex">
                        <TextField
                          placeholder={"MM"}
                          icon={<RxCalendar />}
                          value={formik.values.expiryMonth}
                          change={(e) => {
                            //   formik.setFieldValue(
                            //     "expiry",
                            //     format(new Date(e), "d MMM ")
                            //   );
                            formik.setFieldValue("expiryMonth", e);
                          }}
                          error={formik.errors.expiryMonth}
                          type="text"
                          maxlength={2}
                          width={24}
                        />

                        <TextField
                          placeholder={"YY"}
                          icon={"/"}
                          value={formik.values.expiryYear}
                          change={(e) => {
                            //   formik.setFieldValue(
                            //     "expiry",
                            //     format(new Date(e), "d MMM ")
                            //   );
                            formik.setFieldValue("expiryYear", e);
                          }}
                          error={formik.errors.expiryYear}
                          type="text"
                          maxlength={2}
                          width={100}
                        />
                        {/* <input
                      type="text"
                      id="number"
                      name="number"
                      maxlength="2"
                    /> */}
                      </div>
                      <div className="">
                        <TextField
                          placeholder=" CVV"
                          icon={<MdVpnKey />}
                          value={formik.values.cvv}
                          change={(e) => {
                            formik.setFieldValue("cvv", e);
                          }}
                          error={formik.errors.cvv}
                        />
                      </div>
                    </div>
                    <div className="text-center py-2">
                      <Button onClick={formik.handleSubmit}>{language.ADD_CARD}</Button>
                    </div>

                    <div className=" bg-green-100 rounded-xl my-2 md:mx-5">
                      <p className="text-gray-700 p-2 text-sm">
                        {language.ALERT_ADD_CARD}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </div>
        <div className="zervx_rightimg">
          <img src={settings?.bookingAppImage} alt="" className="w-screen" />

          <div className="global-img-right">
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}
