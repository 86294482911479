import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { TbArrowBigUpLineFilled } from "react-icons/tb";
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { BiMoneyWithdraw } from "react-icons/bi";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { useFormik } from "formik";
// import * as yup from "yup";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import NavLinks from "../../utils/navLinks";
import useAdmin from "../hooks/useAdmin";
import A from "../../utils/urls.utils";
import {
  FaAlignRight,
  FaAngleRight,
  FaIdCard,
  FaPencilAlt,
  FaUser,
} from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { HiChevronRight } from "react-icons/hi";
import { TextField } from "../common/TextFeild";
import { MdVpnKey } from "react-icons/md";
import { Button } from "react-bootstrap";
import { CardDeatilscontext } from "../context/CardDeatilsContext";
import { async } from "@firebase/util";
import { CONST_USER } from "../../utils/constantUtils";
import Modals from "react-bootstrap/Modal";
import { format } from "date-fns";

export default function WalletPage() {
  const { CardDeatilsData, setCardDeatilsData } =
    useContext(CardDeatilscontext);
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { bookingId, amount } = useParams();
  const { header, adminLogout, userProfileFetch } = useAdmin();
  const navigate = useNavigate();

  // const [optionKey, setOptionKey] = useState(
  //   CardDeatilsData?._id ? "topUp" : ""
  // );
  const [optionKey, setOptionKey] = useState("topUp");
  const [walletAmount, setWalletAmount] = useState(""); //" " not an empty act 0
  const [user, setUser] = useState();
  const [walletReachrageStatus, setWalletReachrageStatus] = useState();
  const [show, setShow] = useState(false);
  const [popUpContent, setPopUpContent] = useState(true);
  const [transactionList, setTransactionList] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const [wallet, setWallet] = useState(false);

  useEffect(() => {
    const userProfile = async () => {
      try {
        const { data } = await axios.post(
          A.HOST + A.USER_PROFILE_GET,
          {},
          header
        );
        // alert(JSON.stringify(data));
        setUser(data.data);
        //   console.log(data.data);
      } catch (err) {
        //   authFailure(err);
        // alert(err);
      }
    };
    userProfile();
  }, []);
  const walletReacharge = async () => {
    // alert(walletAmount);
    setBtnLoading(true);

    try {
      const { data } = await axios.post(
        A.HOST + A.WALLET_AMOUNT_RECHARGE,
        {
          paymentGatway: user.paymentGateWay.gateWay,
          userType: CONST_USER,
          amount: walletAmount,
          userId: user.userId,
          cardId: CardDeatilsData?._id,
        },
        header
      );
      setWalletReachrageStatus(data.data);
      // if ((data.data.status = "succeeded")) {
      if (data.data.status === "succeeded") {
        // addWalletAmount();
        if (data.data.transactionId) {
          const { addwalletAmount } = await axios.post(
            A.HOST + A.ADD_WALLET_AMOUNT,
            {
              userType: CONST_USER,
              amount: walletAmount,
              userId: user.userId,
              cardId: CardDeatilsData?._id,
              transactionId: data?.data?.transactionId,
            },
            header
          );
          setWallet(addwalletAmount);
          // alert(JSON.stringify(addwalletAmount));
          console.log(addwalletAmount);

          setShow(true);
          setPopUpContent("AMOUNT IS ADDED TO YOUR WALLET SUCCESSFULLY");
        }
      }
      // alert(data.data.status);
      // setCardDeatilsData({});
      // window.location.reload(true);
      setBtnLoading(false);
    } catch (err) {
      // alert(err);
      setBtnLoading(false);
    }
  };

  const addWalletAmount = async () => {
    if (walletReachrageStatus.transactionId) {
      const { addwalletAmount } = await axios.post(
        A.HOST + A.ADD_WALLET_AMOUNT,
        {
          userType: CONST_USER,
          amount: walletAmount,
          userId: user.userId,
          cardId: CardDeatilsData?._id,
          transactionId: walletReachrageStatus?.transactionId,
        },
        header
      );
      // alert(JSON.stringify(addwalletAmount));
      console.log(addwalletAmount);
      setShow(true);
      setPopUpContent(addwalletAmount.message);
    }
  };
  useEffect(() => {
    // if (optionKey !== "topUp") {
    getTransactionList();
    // }
  }, []);
  const getTransactionList = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.GET_WALLET_TRANSACTION_LIST,
        {
          userType: CONST_USER,
          skip: 0,
          limit: 100,
        },
        header
      );
      setTransactionList(data);
    } catch (err) {
      alert(err);
    }
  };

  if (show) {
    setTimeout(() => {
      setShow(false);
      window.location.reload(true);
    }, 3500);
  }
  return (
    <>
      <Modals show={show} onHide={show}>
        {/* <Modals.Header> */}
        <Modals.Title className="text-center">{popUpContent}</Modals.Title>
        {/* </Modals.Header> */}
      </Modals>
      <main className="zervx_main">
        <div className="zervx_content">
          <section className="header-outer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3 col-3">
                  <div className="header-arrow">
                    <Link to={NavLinks.PROFILE + "/wallet/select"}>
                      <svg
                        className="ola-svg ola-icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-6">
                  <div className="header-logo text-center">
                    <h5>{language.WALLET}</h5>
                  </div>
                </div>
                <div className="col-md-3 col-3">
                  {/* <!-- <div className="header-login">
                        <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

                     </div> --> */}
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Review_section_start --> */}

          <section className="review_main_otr">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-sm-12  flex justify-center  ">
                  {/* Under Contruction...! */}
                  <div className=" col-lg-12 rounded-xl bg-blue-100 px-2 pt-5">
                    <div className="  grid grid-rows-2 text to-black">
                      <div className=" text-center ">
                        <p className="text-black">
                          {settings?.clientCode} {language.WALLET_AMOUNT}
                        </p>
                        <h5 className="text-black">
                          {settings?.currencySymbol}
                          {user?.wallet.availableAmount.toFixed(2) ||
                            wallet?.data?.availableAmount.toFixed(2)}
                        </h5>
                        {!user?.wallet.availableAmount ? (
                          <p className="text-black">
                            {language.ALERT_WALLET_AMOUNT}
                          </p>
                        ) : (
                          <p></p>
                        )}
                      </div>

                      {/* <div className=" flex justify-center py-4  ">
                        <div
                          className="col-md-4  text-center flex items-center cursor-pointer "
                          onClick={() => {
                            setOptionKey("topUp");
                          }}
                        >
                          <div className="hover:bg-blue-50/70 md:mx-4 p-2 px-3 rounded-xl ">
                            <div className=" flex justify-center">
                              <TbArrowBigUpLineFilled />
                            </div>
                            <p className="text-black">Top Up</p>
                          </div>
                        </div>
                        <div
                          className="col-md-4 text-center flex items-center cursor-pointer "
                          onClick={() => {
                            setOptionKey("Recharge");
                          }}
                        >
                          <div className=" hover:bg-blue-50/70 md:mx-4 p-2 px-2 rounded-xl focus:bg-white ">
                            <div className=" flex justify-center  ">
                              <GiReceiveMoney />
                            </div>
                            <p className="text-black ">Recharge</p>
                          </div>
                        </div>
                        <div
                          className="col-md-4 text-center flex items-center cursor-pointer"
                          onClick={() => {
                            setOptionKey("Paid");
                          }}
                        >
                          <div className="hover:bg-blue-50/70 md:mx-4 p-2 px-4 rounded-xl">
                            <div className=" flex justify-center">
                              <BiMoneyWithdraw />
                            </div>
                            <p className="text-black">Paid</p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="py-3 mb-5">
                  {/* {optionKey === "topUp" ? ( */}
                  <div div className=" flex justify-center">
                    <div className="col-md-8 col-12 text-center ">
                      {/* <div className="col-md-12 px-2 bg-blue-400 rounded-xl ">
                        <h5 className="text-white py-2">ZervX Balance</h5>
                        <div className=" flex justify-evenly">
                        <p className="py-1">R$ 500</p>

                        <GiTakeMyMoney className=" w-28" />
                        </div> 
                      </div>*/}
                      <div className="col-md-12 col-12 bg-gray-100 rounded-xl p-2 shadow-md py-2">
                        <h5>{(language.FILL_BALANCE)?.replace("{{clientCode}}", settings?.clientCode)}</h5>
                        <p>{language.TRUST_US}</p>
                      </div>
                      <div
                        className={` ${
                          CardDeatilsData?._id ? "bg-green-200" : "bg-blue-100"
                        }  p-2 flex  justify-between col-lg-12 col-sm-12 rounded-xl mt-3 cursor-pointer shadow-md `}
                        onClick={() => {
                          navigate(NavLinks.CARD_DEATILS + "/wallet");
                        }}
                      >
                        <div className="  flex items-center px-2">
                          {CardDeatilsData?._id ? (
                            <div className="text-green-800  p-2 bg-green-300 rounded-full">
                              <TiTick />
                            </div>
                          ) : (
                            <FaIdCard className="text-blue-400" />
                          )}
                        </div>
                        <div className="grid grid-rows-2 py-2">
                          <span className=" text-start font-semibold text-gray-400">
                            {language.PAYMENT_TYPE}
                          </span>
                          <div className="text-start">
                            <span>{language.PAYMENT}</span>
                          </div>
                        </div>
                        <div className="flex items-center text-end py-2">
                          <div className=" p-2 shadow-md bg-white rounded-full">
                            {CardDeatilsData?._id ? (
                              <FaPencilAlt />
                            ) : (
                              <FaAngleRight className="" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-12 my-2 px-0 py-2">
                        <TextField
                          className="bg-white border-2 rounded-xl font-bold text-black"
                          icon={settings?.currencySymbol}
                          value={walletAmount}
                          onChange={(e) => {
                            setWalletAmount(e);
                          }}
                          type="number"
                        />
                      </div>
                      <div className="col-md-12 col-12 flex justify-evenly py-2">
                        <h6
                          className=" border-2 border-blue-900 rounded-full px-3 p-2 mt-2 mr-1 cursor-pointer"
                          onClick={() => {
                            setWalletAmount(100);
                          }}
                        >
                          {settings?.currencySymbol}
                          100
                        </h6>
                        <h6
                          className=" border-2 border-blue-900 rounded-full px-3 p-2 mt-2 mr-1 cursor-pointer"
                          onClick={() => {
                            setWalletAmount(500);
                          }}
                        >
                          {settings?.currencySymbol}
                          500
                        </h6>
                        <h6
                          className=" border-2 border-blue-900 rounded-full px-3 p-2 mt-2 cursor-pointer "
                          onClick={() => {
                            setWalletAmount(800);
                          }}
                        >
                          {settings?.currencySymbol}
                          800
                        </h6>
                      </div>

                      <p className=" font-semibold text-sm py-2 text-gray-400">
                        {language.SELECT_PAYMENT_METHOD}
                      </p>
                      <Button
                        onClick={() => {
                          walletReacharge();
                        }}
                        className="flex"
                      >
                        {btnLoading === true && (
                          <div className="flex items-center justify-center">
                            <div
                              className="inline-block h-5 w-5 mr-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                              role="status"
                            >
                              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                {/* Loading... */}
                              </span>
                            </div>
                          </div>
                        )}
                        {language.ADD_MONEY}
                      </Button>
                    </div>
                  </div>
                  {/* ) : transactionList ? (
                    <>
                      {optionKey === "Recharge" ? (
                        <>
                          <h4>Wallet Recharge</h4>
                          {transactionList.data.map(
                            (each) =>
                              each.paymentType === "CREDIT" && (
                                <div div className=" flex justify-center">
                                  <div className="col-md-10 col-12 text-center ">
                                    <div
                                      className={`   p-2 flex  justify-between col-lg-12 col-sm-12 rounded-xl mt-3 cursor-pointer shadow-md `}
                                      onClick={() => {
                                        navigate(
                                          NavLinks.CARD_DEATILS + "/wallet"
                                        );
                                      }}
                                    >
                                      <div className="  flex items-center px-2">
                                        {CardDeatilsData?._id ? (
                                          <div className="text-green-800  p-2 bg-green-300 rounded-full">
                                            <TiTick />
                                          </div>
                                        ) : (
                                          <FaIdCard className="text-blue-400" />
                                        )}
                                      </div>
                                      <div className="col-lg-7 p-2 text-start ">
                                        <p className="text-start ">
                                          {format(
                                            new Date(each.transactionDate),
                                            "d MMM yyy p"
                                          )}
                                        </p>
                                        <span className=" text-start font-semibold text-gray-400">
                                          {each.transactionType}
                                        </span>
                                        <div className="text-start text-green-600">
                                          <span>{each.transactionStatus}</span>
                                        </div>
                                      </div>
                                      <div className="flex items-center text-end py-2">
                                        <span
                                          className={`${
                                            each.paymentType === "CREDIT"
                                              ? "text-green-600"
                                              : "text-red-600"
                                          }`}
                                        >
                                          {each.paymentType === "CREDIT"
                                            ? "+" +
                                              settings?.currencySymbol +
                                              each.transactionAmount
                                            : "-" +
                                              settings?.currencySymbol +
                                              each.transactionAmount}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </>
                      ) : (
                        optionKey === "Paid" && (
                          <>
                            <h4>Wallet Paid</h4>
                            {transactionList.data.map(
                              (each) =>
                                each.paymentType === "DEBIT" && (
                                  <div div className=" flex justify-center">
                                    <div className="col-md-10 col-12 text-center ">
                                      <div
                                        className={`   p-2 flex  justify-between col-lg-12 col-sm-12 rounded-xl mt-3 cursor-pointer shadow-md `}
                                        onClick={() => {
                                          navigate(
                                            NavLinks.CARD_DEATILS + "/wallet"
                                          );
                                        }}
                                      >
                                        <div className="  flex items-center px-2">
                                          {CardDeatilsData?._id ? (
                                            <div className="text-green-800  p-2 bg-green-300 rounded-full">
                                              <TiTick />
                                            </div>
                                          ) : (
                                            <FaIdCard className="text-blue-400" />
                                          )}
                                        </div>
                                        <div className="col-lg-7 p-2 text-start ">
                                          <p className="text-start ">
                                            {format(
                                              new Date(each.transactionDate),
                                              "d MMM yyy p"
                                            )}
                                          </p>
                                          <span className=" text-start font-semibold text-gray-400">
                                            {each.transactionType}
                                          </span>
                                          <div className="text-start text-green-600">
                                            <span>
                                              {each.transactionStatus}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="flex items-center text-end py-2">
                                          <span
                                            className={`${
                                              each.paymentType === "CREDIT"
                                                ? "text-green-600"
                                                : "text-red-600"
                                            }`}
                                          >
                                            {each.paymentType === "CREDIT"
                                              ? "+" +
                                                settings.currencySymbol +
                                                each.transactionAmount
                                              : "-" +
                                                settings.currencySymbol +
                                                each.transactionAmount}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          </>
                        )
                      )}
                    </>
                  ) : (
                    <h6>No wallet activity found</h6>
                  )} */}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="zervx_rightimg">
          <img
            src={settings?.bookingAppImage}
            alt=""
            className="w-screen h-screen"
          />

          <div className="global-img-right">
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}
