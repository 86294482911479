import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsStarFill, FiStar } from "react-icons/bs";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { useFormik } from "formik";
// import * as yup from "yup";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import NavLinks from "../../utils/navLinks";
import useAdmin from "../hooks/useAdmin";
import A from "../../utils/urls.utils";

import io from "socket.io-client";
import Cookies from "js-cookie";
import useSocket from "../hooks/useSocket";
import NavBar from "../navBar/NavBar";
import { Modal } from "react-bootstrap";
import { SmallLoader } from "../common/SmallLoader";

export default function Review() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { bookingId, amount } = useParams();
  const { header, user } = useAdmin();
  const navigate = useNavigate();

  const { notificationSocket } = useSocket();

  //   const { user, userProfileFetch } = useAdmin();

  const [userValue, setUserValue] = useState();
  const [pickup, setpickUp] = useState(null);
  const [drop, setdrop] = useState(null);
  const [name, setname] = useState(null);
  const [bookingID, setBookingID] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState(null);
  const [scocketData, setscoketData] = useState(null);

  // useEffect(() => {
  //   alert(JSON.stringify("rideDeatils" + rideDeatils));
  //   // console.log(rideDeatils);
  //   // const value = userProfileFetch();
  //   setUserValue(user);
  //   console.log(user);
  // }, [rideDeatils]);
  // let accesToken = Cookies.get("CBU").substring(13);
  // const socketUrl = "https://socket.cabzone.co.uk/webBooking";
  // // const socketUrl = "http://192.168.1.43:5000/webBooking";
  // let socket = io(socketUrl, {
  //   forceNew: true,
  //   path: "/socket.io/",
  //   reconnection: true,
  //   reconnectionDelay: 2000, //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
  //   reconnectionDelayMax: 60000, //1 minute maximum delay between connections
  //   reconnectionAttempts: "Infinity", //to prevent dead clients, having the user to having to manually reconnect after a server restart.
  //   timeout: 10000,
  //   // transports: [settings?.isSocketPolling === false ? "websocket" : "polling"],
  //   transports: ["websocket"],
  //   query: {
  //     accessToken: `${accesToken}`,
  //     deviceId: accesToken,
  //   },
  // });
  // let socket = io(socketUrl);

  const [bookingDeatils, setBookingDeatils] = useState({
    pickUp: null,
    drop: null,
    name: "",
    bookingID: "",
    bookingOTP: "",
    avatar: "",
    plateNumber: "",
    bookingType: "",
    lat: "",
    lng: "",
    // comment: "",
  });
  const [show, setShow] = useState(false);
  const [popUpContent, setPopUpContent] = useState(true);
  const [rewardData, setRewardData] = useState();

  useEffect(() => {
    setShow(true);

    setPopUpContent(language.RIDE_ENDED);
  }, []);
  if (show) {
    setTimeout(() => {
      setShow(false);
    }, 1000);
  }
  const getBookingDetailById = async () => {
    try {
      // alert(JSON.stringify("bookingId" + bookingId));

      const { data } = await axios.post(
        A.HOST + A.GET_ACCEPT_BOOKING,
        { bookingId: bookingId },
        header
      );
      // setBookedInformation(data.data);
      setBookingDeatils({
        pickUp: data.data.activity.rideStops[0].addressName,
        drop: data.data.activity.rideStops[1].addressName,
        name: data.data.professional.firstName,
        bookingID: data.data.bookingId,
        bookingOTP: data.data.bookingOTP,
        avatar: data.data.professional.avatar,
        plateNumber: data.data.vehicle.plateNumber,
        bookingType: data.data.bookingType,
        lat: data.data.activity.rideStops[1].lat,
        lng: data.data.activity.rideStops[1].lng,
        amount: data.data.invoice.payableAmount,
        currencySymbol: data.data.currencySymbol,
      });
      if (data?.data?.user?.bookingInfo?.pendingReview === null) {
        navigate(NavLinks.REWARDS);
      }
      // else {
      //   navigate(NavLinks.HOME);
      // }
      console.log(data);
      // alert(JSON.stringify(data.data.destination.addressName));
      // console.log(data.data);
    } catch (err) {
      // alert(err);
    }
  };

  useEffect(() => {
    getBookingDetailById();
  }, [bookingId]);

  useEffect(() => {
    // console.log(bookingDeatils);
  }, [bookingDeatils]);

  const submitRating = async () => {
    try {
      // alert(rating);
      // alert(JSON.stringify(comment));
      // alert(JSON.stringify(comment));
      // const usr ="USER"
      const commentValue = document.getElementById("name").value;
      // alert(commentValue);

      // return;
      const { data } = await axios.post(
        A.HOST + A.SUBMIT_RATING, //+ "/user/submitRating", // bookingDeatils.bookingType,
        // A.HOST + "/api/booking/user/submitRating",
        {
          rating: rating,
          comment: commentValue,
          bookingId: bookingId,
          lat: bookingDeatils.lat,
          lng: bookingDeatils.lng,
          // rating,
          // comment,
        },
        header
      );
      localStorage.setItem(
        "Rewards",
        JSON.stringify({
          points: data.data.points,
          rewardType: data.data.rewardType,
          user: bookingDeatils.name,
          bookingId: bookingId,
        })
      );
      setRewardData(data);
      console.log(data);
      // window.location.reload();
      if (data.data.rewardType === "") {
        navigate("/");
      } else {
        navigate(NavLinks.REWARDS);
      }

      // console.log(data);
      // alert(data);
    } catch (err) {
      // alert(err);
    }
    // navigate("/");
  };

  // }, [rating, comment]);
  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  // const user = params.get("name");
  // const room = params.get("room");

  // setUser(user)
  // setRoom(room)
  useEffect(() => {
    notificationSocket.current.on("disconnect", (socket) => {
      console.log(socket.ids); // undefined
    });
  }, []);
  // useEffect(() => {
  //   // return () => {
  //   //   if (socket.readyState === 1) {
  //   //     // <-- This is important
  //   //     socket.close();
  //   //   }
  //   // };
  //   // socket.emit("trackBookingDetailsByIdFromWebBookingApp", () => {
  //   //   console.log("world");
  //   // });
  //   // if (socket.connected) {
  //   //   // socket.on("reconnect", () => {
  //   //   socket.emit(
  //   //     "trackBookingDetailsByIdFromWebBookingApp",
  //   //     {
  //   //       bookingId: "6322f607d00f6024d1d7b3af",
  //   //     },
  //   //     (res) => {
  //   //       setscoketData(res);
  //   //     }
  //   //   );
  //   // socket.on("trackBookingDetailsByIdFromWebBookingApp", (each) => {
  //   //   console.log("each");
  //   //   console.log(each);
  //   // });
  //   // });
  //   // }
  //   // return () => {
  //   //   // User leaves room
  //   //   socket.disconnect();
  //   //   socket.off();
  //   // };

  //   // .....................
  //   socket.on("connect", () => {
  //     // console.log(socket.id);
  //     console.log("---------------");
  //     // console.log(each);
  //   });
  //   // if (socket.connected) {
  //   socket.emit("trackBookingDetailsByIdFromWebBookingApp", {
  //     bookingId: "6322f607d00f6024d1d7b3af",
  //   });
  //   socket.on("trackBookingDetailsByIdFromWebBookingApp", (each) => {
  //     console.log("each");
  //     console.log(each);
  //   });
  //   // }
  // }, [socket]);

  // useEffect(() => {
  //   // if (socket.connected) {
  //   // socket.on("reconnect", () => {
  //   if (socket.connected) {
  //     socket.emit(
  //       "trackBookingDetailsByIdFromWebBookingApp",
  //       {
  //         bookingId: "6322f607d00f6024d1d7b3af",
  //       },
  //       function (res) {
  //         setscoketData(res);
  //       }
  //     );
  //   }
  //   console.log(socket);
  //   // }
  // }, [socket]);

  // useEffect(() => {
  //   try {
  //     // socket.emit("trackBookingDetailsByIdFromWebBookingApp", {
  //     //   bookingId: "6322f607d00f6024d1d7b3af",
  //     // });
  //     socket.on("trackBookingDetailsByIdFromWebBookingApp", (each) => {
  //       console.log("each");
  //       console.log(each);
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }

  //   // socket.on("api", async (msg) => {
  //   //   // setMessages(prevMsg => [...prevMsg, msg])
  //   //   setTimeout(async () => {
  //   //     const { data } = await axios.post(
  //   //       A.HOST + A.GET_ACCEPT_BOOKING,
  //   //       { bookingId: bookingId },
  //   //       header
  //   //     );
  //   //   }, 1000);
  //   // });
  //   // socket.on("roomMembers", (usrs) => {
  //   //   // setUsers(usrs)
  //   // });
  //   console.log(socket);
  // }, [scocketData]);

  return (
    <>
      <Modal show={show} onHide={show}>
        {/* <Modals.Header> */}
        <Modal.Title className="text-center">{popUpContent}</Modal.Title>
        {/* </Modals.Header> */}
      </Modal>
      {bookingDeatils.amount ? (
        <main className="zervx_main">
          <div className="zervx_content">
            {/* <div className=" relative top-0 ">
              <NavBar path="review" bookingId={bookingId} />
            </div> */}
            <section className="header-outer">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-3 col-3">
                    {/* <div className="header-arrow">
                      <Link to={NavLinks.HOME}>
                        <svg
                          className="ola-svg ola-icon"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <g>
                            <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                          </g>
                        </svg>
                      </Link>
                    </div> */}
                  </div>
                  <div className="col-md-6 col-6 pt-10">
                    <div className="header-logo text-center">
                      <h5>{language.THANK_YOU}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-3">
                    {/* <!-- <div className="header-login">
                        <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

                     </div> --> */}
                  </div>
                </div>
              </div>
            </section>

            {/* <!-- Review_section_start --> */}

            <section className="review_main_otr">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="review_content">
                      <h4>{language.RIDE_COMPLETE}</h4>
                      {/* <h1>{rideDeatils.inv.paA + " " + rideDeatils.cS}</h1> */}
                      <h1>
                        {bookingDeatils.currencySymbol +
                          " " +
                          bookingDeatils.amount}
                      </h1>
                      <p>{language.RATE_YOUR_RIDE}</p>
                      <div className="user_icon flex justify-center">
                        <img
                          src={bookingDeatils.avatar}
                          alt=""
                          title=""
                          className="h-48 w-48 rounded-full"
                        />
                      </div>
                      <div className="user_details">
                        <h5>{bookingDeatils.name}</h5>

                        <ul className=" ">
                          <li>
                            <i
                              className={`cursor-pointer  ${
                                rating ? "text-yellow-400 " : ""
                              } `}
                              aria-hidden="true"
                              onClick={() => {
                                setRating(1);
                                // alert(rating);
                                // ratingAndComments.setFieldValue("rating", 1);
                              }}
                            >
                              <BsStarFill />
                            </i>
                          </li>
                          <li>
                            <i
                              className={`cursor-pointer ${
                                rating >= 2 ? "text-yellow-400" : ""
                              } `}
                              aria-hidden="true"
                              onClick={() => {
                                setRating(2);

                                // ratingAndComments.setFieldValue("rating", 2);
                              }}
                            >
                              <BsStarFill />
                            </i>
                          </li>
                          <li>
                            <i
                              className={`cursor-pointer ${
                                rating >= 3 ? "text-yellow-400" : ""
                              } `}
                              aria-hidden="true"
                              onClick={() => {
                                setRating(3);
                                // ratingAndComments.setFieldValue("rating", 3);
                              }}
                            >
                              <BsStarFill />
                            </i>
                          </li>
                          <li>
                            <i
                              className={`cursor-pointer ${
                                rating >= 4 ? "text-yellow-400" : ""
                              } `}
                              aria-hidden="true"
                              onClick={() => {
                                setRating(4);
                                // ratingAndComments.setFieldValue("rating", 4);
                              }}
                            >
                              <BsStarFill />
                            </i>
                          </li>
                          <li>
                            <i
                              className={`cursor-pointer ${
                                rating >= 5 ? "text-yellow-400" : ""
                              } `}
                              aria-hidden="true"
                              onClick={() => {
                                setRating(5);
                                // ratingAndComments.setFieldValue("rating", 5);
                              }}
                            >
                              <BsStarFill onClick={() => {}} />
                            </i>
                          </li>
                        </ul>
                        <div
                          className="review_form"
                          // onSubmit={() => {
                          //   submitRating();
                          // }}
                        >
                          <div className="input-field-common">
                            {/* <!-- <label>From</label> --> */}
                            <textarea
                              type="text"
                              id="name"
                              placeholder={language.LEAVE_YOUR_FEEDBACK}
                              defaultValue={comment}
                              onChange={(e) => {
                                setComment(e);
                                // setBookingDeatils({
                                //   comment: e,
                                // });
                                // alert(JSON.stringify(e));
                                // ratingAndComments.setFieldValue("comment", e);
                              }}
                            ></textarea>
                          </div>
                          <input
                            type="submit"
                            value={language.SUBMIT_FEEDBACK}
                            onClick={() => {
                              submitRating();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="zervx_rightimg">
            <img
              src={settings?.bookingAppImage}
              alt=""
              className="w-screen h-screen"
            />

            <div className="global-img-right">
              <h2>{settings?.bookingAppHomePageMessage}</h2>
            </div>
          </div>
        </main>
      ) : (
        <SmallLoader />
        // rewardData && (
        //   <main className="zervx_main">
        //     <div className="zervx_content">
        //       {/* <div className=" relative top-0 ">
        //         <NavBar path="review" bookingId={bookingId} />
        //       </div> */}
        //       <section className="header-outer">
        //         <div className="container">
        //           <div className="row align-items-center">
        //             <div className="col-md-3 col-3">
        //               {/* <div className="header-arrow">
        //               <Link to={NavLinks.HOME}>
        //                 <svg
        //                   className="ola-svg ola-icon"
        //                   version="1.1"
        //                   xmlns="http://www.w3.org/2000/svg"
        //                   viewBox="0 0 24 24"
        //                 >
        //                   <g>
        //                     <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
        //                   </g>
        //                 </svg>
        //               </Link>
        //             </div> */}
        //             </div>
        //             <div className="col-md-6 col-6">
        //               <div className="header-logo text-center">
        //                 <h5>Thank You</h5>
        //               </div>
        //             </div>
        //             <div className="col-md-3 col-3">
        //               {/* <!-- <div className="header-login">
        //                 <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

        //              </div> --> */}
        //             </div>
        //           </div>
        //         </div>
        //       </section>

        //       {/* <!-- Review_section_start --> */}

        //       <section className="review_main_otr">
        //         <div className="container">
        //           <div className="row">
        //             <div className="col-lg-12 col-sm-12">
        //               <div className="review_content">
        //                 <h4>Congruatulations!!</h4>
        //                 <div className=""></div>
        //                 <div>
        //                   <h6>Name</h6>
        //                   <p>
        //                     Lorem ipsum, dolor sit amet consectetur adipisicing
        //                     elit. Iste mollitia cumque totam obcaecati omnis
        //                     veniam accusantium quae exercitationem ea, non,
        //                     sequi a consequuntur magni nihil cupiditate eius ex
        //                     minus est.
        //                   </p>
        //                   <p>Ponits</p>
        //                 </div>
        //                 <div className="bg-green-500">
        //                   <p>
        //                     Lorem ipsum dolor sit amet consectetur adipisicing
        //                     elit. Magnam quis esse ab rem aspernatur ducimus
        //                     sequi? A fugit commodi nisi voluptates. Accusamus
        //                     quibusdam fugiat sint voluptatum repudiandae modi in
        //                     consectetur!
        //                   </p>
        //                 </div>
        //                 <div className="btn btn primary">
        //                   <button>View All Rewards</button>
        //                   <p>skip</p>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </section>
        //     </div>
        //     <div className="zervx_rightimg">
        //       <img
        //         src={settings?.bookingAppImage}
        //         alt=""
        //         className="w-screen h-screen"
        //       />

        //       <div className="global-img-right">
        //         <h2>{settings?.bookingAppHomePageMessage}</h2>
        //       </div>
        //     </div>
        //   </main>
        // )
      )}
    </>
  );
}
