import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../../utils/navLinks";
import axios from "axios";
import { useParams } from "react-router";
import { Loader } from "@googlemaps/js-api-loader";

import {
  FiThumbsDown,
  FiKey,
  FiPhone,
  FiStar,
  FiPercent,
  FiLifeBuoy,
  FiTriangle,
  FiPhoneCall,
} from "react-icons/fi";

// import zervx_rightimg from "../../assets/images/";
import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import A from "../../utils/urls.utils";
import useAdmin from "../hooks/useAdmin";
import { RideDeatilscontext } from "../context/RideDeatils";
import NavBar from "../navBar/NavBar";
import { Modal } from "react-bootstrap";
import { MdWifiCalling3 } from "react-icons/md";
import { FaPhoneSquareAlt } from "react-icons/fa";
import Notification from "./Notification/index";
import { useNavigate } from "react-router-dom";

export default function StartRide() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { header, user } = useAdmin();
  const { bookingId } = useParams();
  const navigate = useNavigate();

  const mapArea = useRef();
  const { rideDeatilsData, setRideDeatilsData } =
    useContext(RideDeatilscontext);
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [map, setMap] = useState(null);
  const [directionData, setDirectionData] = useState(null);
  const [direction, setDirection] = useState(null);
  const [bookedInformation, setBookedInformation] = useState(null);
  const [pickup, setpickUp] = useState(null);
  const [drop, setdrop] = useState(null);
  const [name, setname] = useState(null);
  const [bookingID, setBookingID] = useState(null);
  const [bookingDeatils, setBookingDeatils] = useState({
    pickUp: null,
    drop: null,
    firstName: "",
    lastName: "",
    bookingID: "",
    bookingOTP: "",
    avatar: "",
    plateNumber: "",
    model: "",
    color: "",
    vehicleCategory: "",
    avgRating: "",
    categoryImage: "",
    professionalPhoneNumber: "",
  });

  const [show, setShow] = useState(false);
  const [popUpContent, setPopUpContent] = useState(true);
  const [picUpLocation, setPicUpLocation] = useState();
  const [dropLocation, setDropLocation] = useState();

  useEffect(() => {
    setShow(true);

    setPopUpContent(language.RIDE_STATED);
  }, []);
  if (show) {
    setTimeout(() => {
      setShow(false);
    }, 1000);
  }
  const getBookingDetailById = async () => {
    try {
      // alert(JSON.stringify("bookingId" + bookingId));

      const { data } = await axios.post(
        A.HOST + A.GET_ACCEPT_BOOKING,
        { bookingId: bookingId },
        header
      );
      if (data.code === 200) {
        setBookedInformation(data.data);
        setBookingDeatils({
          pickUp: data.data.activity.rideStops[0].addressName,
          drop: data.data.activity.rideStops[1].addressName,
          firstName: data.data.professional.firstName,
          lastName: data.data.professional.lastName,
          bookingID: data.data.bookingId,
          bookingOTP: data.data.bookingOTP,
          avatar: data.data.professional.avatar,
          plateNumber: data.data.vehicle.plateNumber,
          model: data.data.vehicle.model,
          color: data.data.vehicle.color,
          vehicleCategory: data.data.vehicle.vehicleCategoryName,
          avgRating: data.data.professional.avgRating,
          categoryImage: data.data.vehicle.vehicleCategoryImage,
          professionalPhoneNumber: data.data.professional.phone,
        });

        switch (data.data.bookingStatus) {
          case "AWAITING":
            navigate(NavLinks.RIDE_BOOKING);
            break;

          case "ACCEPTED":
            navigate(NavLinks.RIDE_CONFIRMED + "/" + bookingId);
            break;

          case "ARRIVED":
            navigate(NavLinks.ARRIVED + "/" + bookingId);
            break;

          case "STARTED":
            navigate(NavLinks.START_RIDE + "/" + bookingId);
            break;

          case "ENDED":
            if (data?.data?.user?.bookingInfo?.pendingReview) {
              navigate(
                NavLinks.RIDE_REVIEW +
                  "/" +
                  data?.data?.user?.bookingInfo.pendingReview
              );
            } else {
              navigate(NavLinks.HOME);
            }
            break;

          default:
            navigate(NavLinks.HOME);
            break;
        }
      } else {
        navigate(NavLinks.HOME);
      }
      console.log(data.data);
    } catch (err) {
      // alert(err);
    }
  };
  useEffect(() => {
    if (bookingId?.length > 10) {
      getBookingDetailById();
    } else {
      navigate(NavLinks.HOME);
    }
  }, [bookingId]);
  useEffect(() => {
    console.log(bookingDeatils);
  }, [bookingDeatils]);

  useEffect(() => {
    setPicUpLocation(JSON.parse(localStorage.getItem("SelectedOption")));
    setDropLocation(JSON.parse(localStorage.getItem("SelectedOption")));
    // alert(JSON.stringify(rootLocation));
    // alert(JSON.stringify(JSON.parse(localStorage.getItem("SelectedOption"))));
  }, []);
  useEffect(() => {
    if (settings?.mapApi?.web) {
      const loader = new Loader({
        apiKey: settings?.mapApi?.web,
        version: "weekly",
        libraries: ["places"],
      });
      loader
        .load()
        .then(() => {
          setGoogleLoaded(true);
        })
        .catch((e) => {});
    }
  }, [settings?.mapApi?.web]);

  useEffect(() => {
    if (googleLoaded) {
      const googleMap = new window.google.maps.Map(mapArea.current, {
        // center: U.mode === "pamworld" ? { lat: 9.476411, lng: 6.600669 } : { lat: 8.9956441, lng: 38.7816384 },
        center: {
          lat:
            rideDeatilsData?.pickUp?.results[0]?.geometry?.location?.lat ||
            picUpLocation?.pickUp?.results[0]?.geometry?.location?.lat ||
            settings.location.lat,
          lng:
            rideDeatilsData?.pickUp?.results[0]?.geometry?.location?.lng ||
            picUpLocation?.pickUp?.results[0]?.geometry?.location?.lng ||
            settings.location.lng,
          //   lat: 28.6134594,
          //   lng: 77.21191,
        },
        zoom: 14,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
      });
      setMap(googleMap);

      // const pickUpMarker = new window.google.maps.Marker({
      //   position: {
      //     lat: settings?.location?.lat,
      //     lng: settings?.location?.lng,
      //   },
      //   map: map,
      // });
    }
  }, [googleLoaded, rideDeatilsData.pickUp?.results]);

  useEffect(() => {
    const check = async () => {
      if (rideDeatilsData?.pickUp !== null && rideDeatilsData?.drop !== null) {
        // if (markers.pickUp !== null) markers.pickUp.setMap(null);
        // if (markers.drop !== null) markers.drop.setMap(null);
        // if (direction !== null) direction.setMap(null);

        // alert(rideDeatilsData?.pickUp.results[0].geometry.location.lat);
        //MARKER
        const coordinatesForPath = [
          {
            lat:
              rideDeatilsData?.pickUp?.results[0]?.geometry?.location.lat ||
              picUpLocation?.pickUp?.results[0]?.geometry?.location?.lat,
            lng:
              rideDeatilsData?.pickUp?.results[0]?.geometry?.location.lng ||
              picUpLocation?.pickUp?.results[0]?.geometry?.location?.lng,
          },
          {
            lat:
              rideDeatilsData?.drop?.results[0]?.geometry?.location.lat ||
              dropLocation?.drop?.results[0]?.geometry?.location?.lat,
            lng:
              rideDeatilsData?.drop?.results[0]?.geometry?.location.lng ||
              dropLocation?.drop?.results[0]?.geometry?.location.lng,
          },
        ];
        var bounds = new window.google.maps.LatLngBounds();
        coordinatesForPath.forEach((poly) => bounds.extend(poly));
        map?.fitBounds(bounds);

        //CREATING DIRECTIONS
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        var request = {
          origin: {
            lat:
              rideDeatilsData?.pickUp?.results[0]?.geometry?.location?.lat ||
              picUpLocation.pickUp?.results[0]?.geometry?.location?.lat,
            lng:
              rideDeatilsData?.pickUp?.results[0]?.geometry?.location?.lng ||
              picUpLocation.pickUp?.results[0]?.geometry?.location?.lng,
          },
          destination: {
            lat:
              rideDeatilsData?.drop?.results[0]?.geometry?.location?.lat ||
              dropLocation?.drop?.results[0]?.geometry?.location?.lat,
            lng:
              rideDeatilsData?.drop?.results[0]?.geometry?.location?.lng ||
              dropLocation?.drop?.results[0]?.geometry?.location?.lng,
          },
          travelMode: "DRIVING",
        };
        // if (stops.filter((stop) => stop.updated === true).length > 0) {
        //   request["waypoints"] = stops
        //     .filter((stop) => stop.updated === true)
        //     .map((stop) => ({
        //       location: new window.google.maps.LatLng(stop.lat, stop.lng),
        //       stopover: true,
        //     }));
        // }
        directionsService.route(request, function (result, status) {
          if (status === "OK") {
            directionsRenderer.setDirections(result);
            setDirectionData(result);
            // console.log("result");
            console.log(result);
          }
        });

        directionsRenderer.setMap(map);
        setDirection(directionsRenderer);

        //#region Intercity Ride
        // if (categories?.serviceCategory === CONST_INTERCITYRIDE.toLowerCase()) {
        //   const pickupDirectionsService = new window.google.maps.DirectionsService();
        //   var pickupRequest = {
        //     origin: {
        //       lat: settings?.location?.lat,
        //       lng: settings?.location?.lng,
        //     },
        //     destination: {
        //       lat: pickUp.results[0].geometry.location.lat,
        //       lng: pickUp.results[0].geometry.location.lng,
        //     },
        //     travelMode: "DRIVING",
        //   };
        //   pickupDirectionsService.route(pickupRequest, function (result, status) {
        //     if (status === "OK") {
        //       setpickupDirectionData(result);
        //     }
        //   });
        // }
        //#endregion Intercity Ride
      }
    };
    if (settings?.mapApi?.web) {
      check();
    }
    // setCategories(null);
  }, [map]);

  return (
    <>
      <Modal
        show={show}
        onHide={show}
        // {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modals.Header> */}
        <Modal.Title className="text-center">{popUpContent}</Modal.Title>
        {/* </Modals.Header> */}
      </Modal>
      <Notification />

      <main className="zervx_main">
        <div className="zervx_content">
          <div className=" sticky top-0 z-50 bg-light">
            <NavBar path="start" bookingId={bookingId} />
          </div>
          <section className="header-outer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3 col-3">
                  {/* <div className="header-arrow">
                  <Link to={NavLinks.HOME}>
                    <svg
                      className="ola-svg ola-icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                      </g>
                    </svg>
                  </Link>
                </div> */}
                </div>
                <div className="col-md-6 col-6">
                  <div className="header-logo text-center">
                    <h5>{bookingID}</h5>
                  </div>
                </div>
                <div className="col-md-3 col-3">
                  {/* <!-- <div className="header-login">
                    <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

                 </div> --> */}
                </div>
              </div>
            </div>
          </section>

          {/* <!-- booking_confirmation_outer --> */}
          <section className="location-form-outer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="bookin-form">
                    {/* <form> */}
                    <div className="input-field-common">
                      <label>{language.PICKUP}</label>
                      {/* <input
                      type="text"
                      id="name"
                      placeholder="Enter Drop PickUp"
                      value={pickUpAddress}
                      readonly
                    /> */}
                      {/* <span>{pickup}</span> */}
                      <span className="md:text-md text-sm">
                        {bookingDeatils.pickUp}
                      </span>
                    </div>
                    <div className="input-field-common">
                      <label>{language.DROP}</label>
                      {/* <input
                      type="text"
                      id="name"
                      placeholder="Enter Drop Location"
                      value={dropAddress}
                      readonly
                    /> */}
                      <span className="md:text-md text-sm">
                        {bookingDeatils.drop}
                      </span>
                    </div>
                    {/* <input
                      type="submit"
                      id="submit"
                      value="Pickup arriving in 4min"
                    />
                  </form> */}
                    <div className="map_outer">
                      <div
                        ref={mapArea}
                        className="bg-white  h-56 rounded-lg m-1"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="booking_confirmation_outer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="booking_confirmation_inner">
                    <div className="booking_confirmation_box">
                      <div className="icon_div">
                        <i className="fa fa-key" aria-hidden="true">
                          {<FiKey />}
                        </i>
                      </div>
                      <div className="content_div">
                        <span className="car_name">{language.OTP}</span>
                      </div>
                      <div className="last-div">
                        <span className="car_price">
                          {/* {bookedInformation.bookingOTP} */}
                          {bookingDeatils.bookingOTP}
                        </span>
                      </div>
                    </div>
                    <div className="booking_confirmation_box">
                      <div className="icon_div">
                        <img
                          src={bookingDeatils.categoryImage}
                          title=""
                          alt=""
                        />
                      </div>
                      <div className="content_div">
                        <span className="car_name">{bookingDeatils.model}</span>
                        <span className="car_type">
                          {bookingDeatils.vehicleCategory}
                        </span>
                      </div>
                      <div className="last-div">
                        <span className="font-bold ">
                          {/* {bookedInformation.vehicle.plateNumber} */}
                          {bookingDeatils.plateNumber}
                        </span>
                        {/* <span className=" font-semibold">
                          {bookingDeatils.model}
                        </span> */}
                        <span className="font-semibold">
                          {bookingDeatils.color}
                        </span>
                        {/*  Car Fare Amount or Car Number  */}
                      </div>
                    </div>
                    <div className="booking_confirmation_box">
                      <div className="icon_div">
                        {/* <img src={profile} title="" alt="" /> */}
                        <img
                          // src={bookedInformation.professional.avatar}
                          src={bookingDeatils.avatar}
                          title=""
                          alt=""
                        />
                      </div>
                      <div className="content_div">
                        <span className="drvier_name">
                          {bookingDeatils.firstName +
                            " " +
                            bookingDeatils.lastName}
                        </span>
                        <span className="rating py-2">
                          <i className=" flex" aria-hidden="true">
                            <div className="pr-2">
                              {(bookingDeatils?.avgRating || 0).toFixed(2)}
                            </div>
                            {/* {bookingDeatils.avgRating.filter(() => (
                              <div className=" text-yellow-500">
                                {<FiStar />}
                              </div>
                            ))} */}
                            <div className=" text-yellow-500">{<FiStar />}</div>
                          </i>
                        </span>
                      </div>
                      <div className="last-div flex justify-center bg-blue-900   align-middle items-center rounded-full p-2 py-3 text-white ">
                        <a
                          // href={`tel:${settings?.callCenterPhone}`}
                          href={`tel:${bookingDeatils?.professionalPhoneNumber?.number}`}
                          className=" font-semibol "
                        >
                          {/* {settings?.callCenterPhone}
                           */}
                          <FiPhoneCall className="text-white" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="booking_confirmation_inner">
                    {/* <div className="booking_confirmation_box">
                     <div className="icon_div">
                      <i className="fa fa-percent" aria-hidden="true">
                        {<FiPercent />}
                      </i>
                    </div>
                    <div className="content_div">
                      <span className="car_name">Apply Coupon</span>
                    </div>
                  </div> */}
                    {/* <div className="booking_confirmation_box">
                      <div className="icon_div">
                        <i className="fa fa-life-ring" aria-hidden="true">
                          {<FiLifeBuoy />}
                        </i>
                      </div>
                      <div className="content_div">
                        <span className="car_name">Support</span>
                      </div>
                    </div> */}
                  </div>
                  {/* 
                <div className="booking_confirmation_inner">
                  <div className="booking_confirmation_box cancel_div">
                    <div className="icon_div">
                      <i className="fa fa-times-circle" aria-hidden="true">
                        {<FiTriangle />}
                      </i>
                    </div>
                    {/* <div className="content_div">
                      <span className="car_name">Cancel</span>
                    </div> *
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="zervx_rightimg"
          // style="background-image: url('images/bg_city.jpg');"
        >
          <img
            src={settings?.bookingAppImage}
            alt=""
            className="w-screen h-screen"
          />

          <div className="global-img-right">
            {/* <h2>
                Ride with <span>Comfort</span>
              </h2>
              <h2>
                Ride with <span>ZervX</span>
              </h2> */}
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}
