import { React, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
// import { history } from "react-dom";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";

import A from "../../utils/urls.utils";
import utils from "../../utils/utils";
import NavLinks from "../../utils/navLinks";
// import useNavigate from "../../utils/navLinks";
// import useUtils from "./useUtils";
// import privileges from "../../utils/privileges";
// import useUtils from "./useUtils";

export const adminAtom = atom({
  key: "adminAtom",
  default: null,
});
export default function useAdmin() {
  //   const { mergePrivilege } = useUtils();
  const navigate = useNavigate();
  //   const { mergePrivilege } = useUtils();

  // const [admin, setAdmin] = useRecoilState(adminAtom);
  const [userProfileData, setUserProfile] = useRecoilState(adminAtom);
  //   const remember = Cookies.get("AD") ? JSON.parse(Cookies.get("AD")) : null;
  const [user, setUser] = useState();
  const [ipAddress, setIpAddress] = useState();
  const [getCookies, setGetCookies] = useState(); //Cookies.get("CBU")
  const [hearder, sethearder] = useState();

  useEffect(() => {
    // sethearder(JSON.parse(localStorage.getItem("CBU")));
    // console.log(JSON.parse(localStorage.getItem("CBU")));
    // alert(JSON.parse(localStorage.getItem("CBU")));
  }, []);
  //     setAdmin({
  //       headers: {
  //         Authorization: `Bearer ${userProfile.accessToken}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //   }, [userProfile]);
  //   alert("adminProfile_" + JSON.stringify(userProfile));
  // setGetCookies(Cookies.get("CBU").substring(13));
  let header = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("CBU"))}`,
      "Content-Type": "application/json",
      "X-Forwarded-For": ipAddress,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,POST",
      "Access-Control-Allow-Headers":
        "Content-Type ,Origin, X-Requested-With, Accept, Authorization",
    },
  };
  // : null;
  //   const fetchClientDetails = async () => {
  //     try {
  //       const reponse = await axios.post(
  //         A.CLIENT_CONFIG.HOST + A.CLIENT_CONFIG.urls.GET_CLIENT_PAYMENT_DETAILS,
  //         { key: utils.mode },
  //         header
  //       );
  //       return reponse;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  const userLogIn = async (object, cookie = true) => {
    try {
      const { data } = await axios.post(
        A.HOST + A.USER_LOG_IN_CONFIRM_OTP,
        object
      );

      //   const response = await fetchClientDetails();
      //   alert(data.data.accessToken);

      if (cookie === true)
        Cookies.set("CBU", Date.now() + data.data.accessToken, {
          // expires: 30, // 0.01
        });
      localStorage.setItem("CBU", JSON.stringify(data.data.accessToken));

      console.log(data);
      setUserProfile(data.data);
      header = data.data
        ? {
            headers: {
              Authorization: `Bearer ${data.data.accessToken}`,
              "Content-Type": "application/json",
              "X-Forwarded-For": ipAddress,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,POST",
              "Access-Control-Allow-Headers":
                "Content-Type ,Origin, X-Requested-With, Accept, Authorization",
            },
          }
        : null;

      if (data.languageCode !== undefined) {
        Cookies.set("lanu", data.languageCode);
        // fetchLanguage(data.languageCode);
      }
      userProfileFetch();
      // alert(JSON.stringify(header));
      //   console.log("userProfile" + " " + JSON.stringify(userProfile));

      return true;
      //   setAdmin(mergePrivileges(data));
      //------- For Client Payment/Plan Management Start ------------------
      //   if (response?.data?.data) {
      //     const today = new Date().getTime();
      //     const billingDate = new Date(response.data.data.billingDate);
      //     const expiryDate = new Date(billingDate).setDate(
      //       billingDate.getDate() + response.data.data.expiryDays
      //     );
      //     const finalExpiry = new Date(billingDate).setDate(
      //       billingDate.getDate() + (response.data.data.expiryDays + 1)
      //     );

      // if (today >= finalExpiry) {
      //   alert("Please Contact Admin");
      //   //alert("Plan Expired");
      //   // Cookies.remove("AD");
      //   //   setAdmin(null);
      //   setAdminProfile(null);
      //   Cookies.remove("AD");
      //   //   history.push(NavLinks.ADMIN_HOME);
      // } else {
      //   //alert("Plan not Expired");
      // }
      //   }
      //------- For Client Payment/Plan Management End ------------------
    } catch (err) {
      // return err;
      return false;
      console.log(err);
    }
  };
  //   const mergePrivileges = (data) => {
  //     return Cookies.get("AD")
  //       ? data.extraPrivileges
  //         ? {
  //             ...data,
  //             privileges: mergePrivilege(
  //               Privileges,
  //               mergePrivilege(
  //                 data.privileges || Privileges,
  //                 data.extraPrivileges || {}
  //               )
  //             ),
  //           }
  //         : data
  //       : null;
  //   };

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch("https://api.ipify.org/?format=json"); //,"https://ipapi.co/json/"
    const data = await response.json();
    // Set the IP address to the constant `ip`
    setIpAddress(data.ip);
    console.log(data.ip);
  };
  // Run `getIP` function above just once when the page is rendered
  useEffect(() => {
    getIp();
  }, []);

  const userProfileFetch = async () => {
    try {
      if (header.headers.Authorization) {
        const { data } = await axios.post(
          A.HOST + A.USER_PROFILE_GET,
          {},
          header
        );
        // alert(JSON.stringify(data));
        setUser(data.data);
      }
    } catch (err) {
      //   authFailure(err);
      // alert(err);
    }
  };

  const adminLogout = () => {
    // alert("adminLogout");
    setUserProfile(null);
    Cookies.remove("CBU");
    localStorage.setItem("CBU", JSON.stringify({}));
    localStorage.removeItem("CBU");

    Cookies.remove("locu");
    Cookies.remove("lanu");
    localStorage.setItem(
      "CurrentLocation",
      JSON.stringify({ lat: "", lng: "" })
    );
    localStorage.setItem("place_Id", JSON.stringify({}));
    localStorage.setItem("SelectedOption", JSON.stringify({}));
    localStorage.removeItem("CurrentLocation");
    localStorage.removeItem("place_Id");
    localStorage.removeItem("SelectedOption");
    // history.push(NavLinks.ADMIN_HOME);
    // window.location.reload(true);
    navigate(NavLinks.HOME);
  };

  //   const mergePrivileges = (data) => {
  //     return Cookies.get("AD")
  //       ? data.extraPrivileges
  //         ? {
  //             ...data,
  //             privileges: mergePrivilege(
  //               Privileges,
  //               mergePrivilege(
  //                 data.privileges || Privileges,
  //                 data.extraPrivileges || {}
  //               )
  //             ),
  //           }
  //         : data
  //       : null;
  //   };
  //   const checkAdmin = async () => {
  //     const credentials = Cookies.get("AD")
  //       ? JSON.parse(Cookies.get("AD"))
  //       : null;
  //     if (credentials == null)
  //       //setAdmin(credentials);
  //       setAdminProfile(null);
  //     else {
  //       try {
  //         const { data } = await axios.post(
  //           A.HOST + A.ADMIN_VALIDATE,
  //           {},
  //           {
  //             headers: {
  //               Authorization: `Bearer ${credentials.accessToken}`,
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );
  //         setAdminProfile(data);
  //         // alert(JSON.stringify(data));
  //         // if (data.languageCode !== undefined) {
  //         //   Cookies.set("lan", data.languageCode);
  //         //   //   fetchLanguage(data.languageCode);
  //         // }
  //         // // setAdmin(mergePrivileges(data));
  //         return true;
  //       } catch (err) {
  //         return false;
  //       }
  //     }
  //   };
  // console.log(header);
  // const token = user != null ? userProfile.accessToken : null;

  return {
    user,
    userProfileData,
    userLogIn,
    userProfileFetch,
    // token,
    // checkAdmin,
    adminLogout,
    header,
    ipAddress,
  };
}
