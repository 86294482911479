import React from "react";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import gsap from "gsap";

export const PopUp = ({ type = "success", title, unmount = () => {} }) => {
  React.useEffect(() => {
    gsap.fromTo(".popup", 0.3, { opacity: 0 }, { opacity: 1, stagger: 0.1 });
    setTimeout(() => {
      gsap.fromTo(
        ".popup",
        0.5,
        { opacity: 1, y: 0 },
        { opacity: 0, y: 10, stagger: 0.2 }
      );
    }, 3000);
    setTimeout(() => {
      unmount();
    }, 3500);
  }, []);
  return (
    <div className="opacity-0 popup fixed left-0 right-0 top-0 flex justify-center z-30 mt-20">
      <div className="opacty-0 popup bg-white flex items-center dark:bg-gray-900 dark:text-gray-200 text-sm text-gray-800 shadow-xl p-3 rounded-xl">
        <div
          className={`opacity-0 popup p-2 text-white rounded-full ${
            document.getElementsByTagName("html")[0].getAttribute("dir") ===
            "ltr"
              ? "mr-2"
              : "ml-2"
          }`}
          style={{
            backgroundColor: type === "success" ? "#248967" : "#F66766",
          }}
        >
          {type === "success" ? <FiThumbsUp /> : <FiThumbsDown />}
        </div>
        <p
          className="opacity-0 popup"
          style={{
            color: type === "success" ? "#248967" : "#F66766",
          }}
        >
          {title}
        </p>
      </div>
    </div>
  );
};
