import { React, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import MainRouter from "./Component/cabBooking/routes/MainRouter";
import css from "./Component/assets/css/style.css";
import css2 from "./Component/assets/css/bootstrap.css";
import css3 from "./Component/assets/css/responsive.css";
import useSettings from "./Component/cabBooking/hooks/useSettings";
import useLanguage from "./Component/cabBooking/hooks/useLanguage";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RideDeatilsProvider from "./Component/cabBooking/context/RideDeatils";
import CardDeatilsProvider from "./Component/cabBooking/context/CardDeatilsContext";
import socketIO from "socket.io-client";
import useSocket from "./Component/cabBooking/hooks/useSocket";
import { Loader } from "@googlemaps/js-api-loader";
// const { settings, fetchSettings } = useSettings();
// import css4 from "./Component/assets/css/font-awesome.min.css";
// import Notification from "./Component/cabBooking/home/Notification";
// function App() {
//   const { settings, fetchSettings } = useSettings();

//   const fetchingSettings = async () => await fetchSettings();
//   useEffect(() => {
//     fetchingSettings();
//   }, []);

//   useEffect(() => {
//     alert(JSON.stringify(1));
//     alert(JSON.stringify(settings));
//   }, [settings]);

//   // const fetchingBasicThings = async () => {
//   //   const { data } = await fetchingSettings();
//   //   if (data) {
//   //     data.languageDirection === "RTL" ? alignRight() : alignLeft();
//   //   }
//   //   if (data && data.languageCode) await fetchingLanguage(data.languageCode);
//   // };
//   return (
//     <div>
//       <MainRouter />
//     </div>
//   );
// }

// export default App;

export default function App() {
  const { settings, fetchSettings } = useSettings();
  const { language, fetchLanguage } = useLanguage();

  const { notificationSocket } = useSocket();

  const fetchingSettings = async () => await fetchSettings();
  const fetchingLanguage = async (lang) => await fetchLanguage(lang);

  const fetchingBasicThings = async () => {
    const { data } = await fetchingSettings();
    // if (data) {
    //   data.languageDirection === "RTL" ? alignRight() : alignLeft();
    // }
    // {alert(data.languageCode)}
    if (data && data.languageCode) await fetchingLanguage(data.languageCode);
    // if (data && data.languageCode) await fetchingLanguage("pt-br");
    // if (data && data.languageCode) await fetchingLanguage("ta");
    // if (data && data.clientId) await fetchingMapSettings(data.clientId);
  };

  useEffect(() => {
    // const fetchingBasicThings = async () => {
    //   const { data } = await fetchSettings();
    //   alert("data");
    //   alert(data);
    // };
      fetchSettings();
      fetchingBasicThings()
  }, []);

  useEffect(() => {
    if (settings != null) {
      // alert(JSON.stringify(1));
      // alert(JSON.stringify(settings));
      console.log("settings");
      console.log(settings);
      console.log("settings");
    }
  }, []);
  useEffect(() => {
    // console.log("------------");
    // console.log(settings);
    // console.log("------------");
    if (settings !== null && settings?.mapApi?.web) {
      // console.log(JSON.stringify(settings));
      const loader = new Loader({
        apiKey: settings?.mapApi?.web,
        // apiKey: "AIzaSyBbdeSXbRBE0Ts6G8q4XR0uoa8o5KYlnDI",
        version: "weekly",
        libraries: ["places"],
      });
      loader
        .load()
        .then(() => {
          // setGoogleLoaded(true);
        })
        .catch((e) => {});
    }
  }, [settings, settings?.mapApi?.web]);


  // useEffect(() => console.log(notificationSocket), [notificationSocket]);

  // useEffect(() => {
  //   console.log("Profile");
  //   console.log(notificationSocket);
  //   if (notificationSocket.connected) {
  //     console.log("notificationSocket_______1");

  //     notificationSocket.current.on(
  //       "trackBookingStatusForWebBookingApp",
  //       (data) => {
  //         console.log(JSON.stringify(data));
  //       }
  //     );
  //     console.log("notificationSocket_______2");
  //   }
  // }, [notificationSocket]);

  // fetchSettings();

  // const fetchingBasicThings = async () => {
  //   const { data } = await fetchingSettings();
  //   if (data) {
  //     data.languageDirection === "RTL" ? alignRight() : alignLeft();
  //   }
  //   if (data && data.languageCode) await fetchingLanguage(data.languageCode);
  // };
  return (
    <div>
      <RideDeatilsProvider>
        <CardDeatilsProvider>
          <MainRouter />
        </CardDeatilsProvider>
      </RideDeatilsProvider>
    </div>
  );
}
