import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../../utils/navLinks";
import axios from "axios";
import { useParams } from "react-router";
import { Loader } from "@googlemaps/js-api-loader";
import Cookies from "js-cookie";
import Notification from "./Notification/index";
import {
  FiThumbsDown,
  FiKey,
  FiPhone,
  FiStar,
  FiPercent,
  FiLifeBuoy,
  FiTriangle,
  FiPhoneCall,
} from "react-icons/fi";

// import zervx_rightimg from "../../assets/images/";
import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import A from "../../utils/urls.utils";
import useAdmin from "../hooks/useAdmin";
import { useNavigate } from "react-router-dom";
import { RideDeatilscontext } from "../context/RideDeatils";
import Modal from "react-bootstrap/Modal";

import { Button } from "react-bootstrap";
import NavBar from "../navBar/NavBar";
import { MdWifiCalling3 } from "react-icons/md";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { SmallLoader } from "../common/SmallLoader";

export default function BookingConfirmed() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { header, user } = useAdmin();
  const { bookingId } = useParams();
  const navigate = useNavigate();
  const mapArea = useRef();
  const { rideDeatilsData, setRideDeatilsData } =
    useContext(RideDeatilscontext);
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [map, setMap] = useState(null);
  const [directionData, setDirectionData] = useState(null);
  const [direction, setDirection] = useState(null);
  let intervalId;

  const [bookedInformation, setBookedInformation] = useState(null);
  const [pickup, setpickUp] = useState(null);
  const [drop, setdrop] = useState(null);
  const [name, setname] = useState(null);
  const [bookingID, setBookingID] = useState(null);
  const [rideStatus, setRideStatus] = useState("");
  const [searchingProfessional, setSearchingProfessional] = useState(false);
  const [bookingDeatils, setBookingDeatils] = useState({
    pickUp: null,
    drop: null,
    firstName: "",
    lastName: "",
    bookingID: "",
    bookingOTP: "",
    avatar: "",
    plateNumber: "",
    model: "",
    color: "",
    vehicleCategory: "",
    avgRating: "",
    categoryImage: "",
    professionalPhoneNumber: "",
  });
  const [estmationTravelDistance, setEstmationTravelDistance] = useState(0);
  const [estmationTravelTime, setEstmationTravelTime] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [popUpContent, setPopUpContent] = useState(true);
  const [cancelReasion, setCancelReasion] = useState();
  const [picUpLocation, setPicUpLocation] = useState();
  const [dropLocation, setDropLocation] = useState();

  useEffect(() => {
    setShow(true);
    setPopUpContent(language.RIDE_ACCEPTED);
  }, [bookingId]);
  if (show) {
    setTimeout(() => {
      setShow(false);
    }, 1000);
  }

  const getBookingDetailById = async () => {
    try {
      // alert(JSON.stringify("bookingId" + bookingId));

      const { data } = await axios.post(
        A.HOST + A.GET_ACCEPT_BOOKING,
        { bookingId: bookingId },
        header
      );
      // console.log(data.data);
      if (data.code === 200) {
        setBookedInformation(data.data);
        // setpickUp(data.data.destination.addressName);
        // setdrop(data.data.origin.addressName);
        // setname(data.data.professional.firstName);
        // setBookingID(data.data.bookingId);
        setBookingDeatils({
          pickUp: data.data.activity.rideStops[0].addressName,
          drop: data.data.activity.rideStops[1].addressName,
          firstName: data.data.professional.firstName,
          lastName: data.data.professional.lastName,
          bookingID: data.data.bookingId,
          bookingOTP: data.data.bookingOTP,
          avatar: data.data.professional.avatar,
          plateNumber: data.data.vehicle.plateNumber,
          model: data.data.vehicle.model,
          color: data.data.vehicle.color,
          vehicleCategory: data.data.vehicle.vehicleCategoryName,
          avgRating: data.data.professional.avgRating,
          categoryImage: data.data.vehicle.vehicleCategoryImage,
          professionalPhoneNumber: data.data.professional.phone,
        });
        switch (data.data.bookingStatus) {
          case "AWAITING":
            navigate(NavLinks.RIDE_BOOKING);
            break;

          case "ACCEPTED":
            navigate(NavLinks.RIDE_CONFIRMED + "/" + bookingId);
            break;

          case "ARRIVED":
            navigate(NavLinks.ARRIVED + "/" + bookingId);
            break;

          case "STARTED":
            navigate(NavLinks.START_RIDE + "/" + bookingId);
            break;

          case "ENDED":
            if (data?.data?.user?.bookingInfo?.pendingReview) {
              navigate(
                NavLinks.RIDE_REVIEW +
                  "/" +
                  data?.data?.user?.bookingInfo.pendingReview
              );
            } else {
              navigate(NavLinks.HOME);
            }
            break;

          default:
            navigate(NavLinks.HOME);
            break;
        }
      } else {
        navigate(NavLinks.HOME);
      } // alert(JSON.stringify(data.data.destination.addressName));

      // alert(Cookies.get("CBU").substring(13));
    } catch (err) {
      // alert(err);
      // navigate(NavLinks.YOUR_RIDE_VIEW_UCOMING_RIDES);
    }
  };

  useEffect(() => {
    // alert("Conform" + bookingId);
    if (bookingId?.length > 10) {
      getBookingDetailById();
    } else {
      navigate(NavLinks.HOME);
    }
  }, [bookingId]);

  // useEffect(() => {
  //   console.log(bookingDeatils);
  // }, [bookingDeatils]);

  const cancelRide = async (value) => {
    const data = await axios.post(
      A.HOST + A.CANCEL_BOOKING,
      {
        bookingId: bookingId,
        cancellationReason: value,
      },
      header
    );
    navigate(NavLinks.RIDE_BOOKING);
  };
  // const fetchBookingDeatils = async (each) => {
  //   //   bookingType: bookingType, //CONST_INSTANT,

  //   // if (bookingId && bookingType === CONST_INSTANT) {
  //   try {
  //     const { data } = await axios.post(
  //       A.HOST + A.TRACK_BOOKING,
  //       { bookingId: bookingId, bookingStatus: rideStatus },
  //       header
  //     );
  //     setRideStatus(data.data.bS);

  //     // console.log("header" + JSON.stringify(rideStatus));
  //     //   }
  //     // if (data.data.bS === "AWAITHING") {
  //     // setSearchingProfessional(true);
  //     navigate(NavLinks.SEARCHING_PROFESSIONALS + "/" + bookingId);
  //     // } else
  //     setSearchingProfessional(true);
  //     if (data.data.bS === "AWAITING") {
  //       // retry();
  //       // clearInterval(intervalId);
  //     } else if (data.data.bS === "ACCEPTED" || data.data.bS === "ARRIVED") {
  //       //   alert(data.data.bS);
  //       //   navigate(
  //       //     NavLinks.RIDE_CONFIRMED +
  //       //       "/" +
  //       //   pickUp.selectedAddress.formatted_address ||
  //       //     pickUp.selectedAddress + "/" + drop.selectedAddress;
  //       //   );
  //       setSearchingProfessional(false);

  //       setRideStatus(data.data.bS);

  //       navigate(NavLinks.RIDE_CONFIRMED + "/" + bookingId);
  //       //   setRideAcepted(true);
  //     } else if (data.data.bS === "STARTED") {
  //       setSearchingProfessional(false);

  //       navigate(NavLinks.START_RIDE + "/" + bookingId);
  //     } else if (data.data.bS === "ENDED") {
  //       setSearchingProfessional(false);
  //       navigate(NavLinks.RIDE_REVIEW + "/" + bookingId);

  //       setRideStatus(data.data.bS);
  //       clearInterval(intervalId);

  //       //   setRideAcepted(false);
  //       //   setRideStatus(data.data.bS === "ENDED" ? true : false);

  //       navigate(NavLinks.RIDE_REVIEW + "/" + bookingId);
  //     } else if (
  //       data.data.bS === "USERCANCELLED" ||
  //       data.data.bS === "USERDENY"
  //     ) {
  //       setSearchingProfessional(false);

  //       clearInterval(intervalId);
  //       navigate(NavLinks.RIDE_BOOKING);
  //     } else if (data.data.bS === "EXPIRED") {
  //       // setPop({ title: "No Professional Found ", type: "error" });
  //     }
  //     // else {
  //     //   navigate(NavLinks.START_RIDE + "/" + bookingId);
  //     // }
  //     // clearInterval(intervalId);

  //     //   console.log(data);
  //   } catch (err) {
  //     clearInterval(intervalId);
  //     //   alert(err);
  //   }
  //   // } else {
  //   //   navigate(NavLinks.SCHEDULE_RIDE_BOOKING + "/" + bookingId);
  //   // }
  // };

  useEffect(() => {
    setPicUpLocation(JSON.parse(localStorage.getItem("SelectedOption")));
    setDropLocation(JSON.parse(localStorage.getItem("SelectedOption")));
    // alert(JSON.stringify(rootLocation));
    // alert(JSON.stringify(JSON.parse(localStorage.getItem("SelectedOption"))));
  }, []);

  useEffect(() => {
    if (settings?.mapApi?.web) {
      const loader = new Loader({
        apiKey: settings?.mapApi?.web,
        version: "weekly",
        libraries: ["places"],
      });
      loader
        .load()
        .then(() => {
          setGoogleLoaded(true);
        })
        .catch((e) => {
          alert(e);
        });
    }
  }, [settings?.mapApi?.web]);

  useEffect(() => {
    console.log(settings);
    if (googleLoaded) {
      const googleMap = new window.google.maps.Map(mapArea.current, {
        // center: U.mode === "pamworld" ? { lat: 9.476411, lng: 6.600669 } : { lat: 8.9956441, lng: 38.7816384 },
        center: {
          // lat:
          //   rideDeatilsData?.pickUp?.results[0]?.geometry?.location?.lat ||
          //   picUpLocation?.pickUp?.results[0]?.geometry?.location?.lat ||
          //   settings.location.lat,
          // lng:
          //   rideDeatilsData?.pickUp?.results[0]?.geometry?.location?.lng ||
          //   picUpLocation?.pickUp?.results[0]?.geometry?.location?.lng ||
          //   settings.location.lng,
          lat: bookedInformation?.professional?.lat,
          lng: bookedInformation?.professional?.lng,
        },
        zoom: 14,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
      });
      setMap(googleMap);

      // const pickUpMarker = new window.google.maps.Marker({
      //   position: {
      //     lat: settings?.location?.lat,
      //     lng: settings?.location?.lng,
      //   },
      //   map: map,
      // });
    }
  }, [googleLoaded, bookedInformation?.professional?.lat]);
  useEffect(() => {
    const check = async () => {
      if (rideDeatilsData?.pickUp !== null && rideDeatilsData?.drop !== null) {
        // if (markers.pickUp !== null) markers.pickUp.setMap(null);
        // if (markers.drop !== null) markers.drop.setMap(null);
        // if (direction !== null) direction.setMap(null);

        // alert(rideDeatilsData?.pickUp.results[0].geometry.location.lat);
        //MARKER
        // if (new window.google.maps() !== undefined) {
        const coordinatesForPath = [
          {
            lat:
              rideDeatilsData?.pickUp?.results[0]?.geometry?.location.lat ||
              picUpLocation?.pickUp?.results[0]?.geometry?.location?.lat,
            lng:
              rideDeatilsData?.pickUp?.results[0]?.geometry?.location.lng ||
              picUpLocation?.pickUp?.results[0]?.geometry?.location?.lng,
          },
          {
            // lat:
            //   rideDeatilsData?.drop?.results[0]?.geometry?.location.lat ||
            //   dropLocation?.drop?.results[0]?.geometry?.location?.lat,
            // lng:
            //   rideDeatilsData?.drop?.results[0]?.geometry?.location.lng ||
            //   dropLocation?.drop?.results[0]?.geometry?.location.lng,
            lat: bookedInformation?.professional?.lat,
            lng: bookedInformation?.professional?.lng,
          },
        ];
        var bounds = new window.google.maps.LatLngBounds();
        coordinatesForPath.forEach((poly) => bounds.extend(poly));
        map?.fitBounds(bounds);
        //CREATING DIRECTIONS
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        var request = {
          origin: {
            lat:
              rideDeatilsData?.pickUp?.results[0]?.geometry?.location.lat ||
              picUpLocation?.pickUp?.results[0]?.geometry?.location?.lat,
            lng:
              rideDeatilsData?.pickUp?.results[0]?.geometry?.location.lng ||
              picUpLocation?.pickUp?.results[0]?.geometry?.location?.lng,
          },
          destination: {
            // lat:
            //   rideDeatilsData?.drop?.results[0]?.geometry?.location.lat ||
            //   dropLocation.drop?.results[0]?.geometry?.location?.lat,
            // lng:
            //   rideDeatilsData?.drop?.results[0]?.geometry?.location.lng ||
            //   dropLocation?.drop?.results[0]?.geometry?.location.lng,
            lat: bookedInformation?.professional?.lat,
            lng: bookedInformation?.professional?.lng,
          },
          travelMode: "DRIVING",
        };
        // if (stops.filter((stop) => stop.updated === true).length > 0) {
        //   request["waypoints"] = stops
        //     .filter((stop) => stop.updated === true)
        //     .map((stop) => ({
        //       location: new window.google.maps.LatLng(stop.lat, stop.lng),
        //       stopover: true,
        //     }));
        // }
        directionsService.route(request, function (result, status) {
          if (status === "OK") {
            directionsRenderer.setDirections(result);
            setDirectionData(result);
            // console.log("result");
            setEstmationTravelDistance(
              result.routes[0].legs
                .map((each) => each.distance.value)
                .reduce((a, b) => a + b)
            );
            setEstmationTravelTime(
              result.routes[0].legs
                .map((each) => each.duration.value)
                .reduce((a, b) => a + b)
            );
            console.log(result);
          }
        });

        directionsRenderer.setMap(map);
        setDirection(directionsRenderer);
        // }

        //#region Intercity Ride
        // if (categories?.serviceCategory === CONST_INTERCITYRIDE.toLowerCase()) {
        //   const pickupDirectionsService = new window.google.maps.DirectionsService();
        //   var pickupRequest = {
        //     origin: {
        //       lat: settings?.location?.lat,
        //       lng: settings?.location?.lng,
        //     },
        //     destination: {
        //       lat: pickUp.results[0].geometry.location.lat,
        //       lng: pickUp.results[0].geometry.location.lng,
        //     },
        //     travelMode: "DRIVING",
        //   };
        //   pickupDirectionsService.route(pickupRequest, function (result, status) {
        //     if (status === "OK") {
        //       setpickupDirectionData(result);
        //     }
        //   });
        // }
        //#endregion Intercity Ride
      }
    };
    if (settings?.mapApi?.web) {
      check();
    }
    // setCategories(null);
  }, [
    map,
    rideDeatilsData?.drop,
    rideDeatilsData?.pickUp,
    settings?.mapApi?.web,
  ]);
  const CancelReasion = async () => {
    const data = await axios.post(A.HOST + A.CANCELLATIONREASON, {}, header);
    setCancelReasion(data.data.data);
    // alert(JSON.stringify(data));
    // console.log(data.data.data);
  };
  // useEffect(() => {
  //   alert(JSON.stringify(bookingDeatils));
  // }, []);

  return (
    <>
      {bookingDeatils ? (
        <div>
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {language.ALERT_CANCEL_RIDE}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <h4>Centered Modal</h4> */}
              {cancelReasion
                ? cancelReasion.map((each) => (
                    <div
                      className=" cursor-pointer py-2 font-bold hover:border hover:border-green-400 text-center rounded-xl"
                      onClick={() => {
                        cancelRide(each.reason);
                      }}
                    >
                      {each.reason}
                    </div>
                  ))
                : null}
            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              cancelRide();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer> */}
          </Modal>
          <Modal
            show={show}
            onHide={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {/* <Modals.Header> */}
            <Modal.Title className="text-center">{popUpContent}</Modal.Title>
            {/* </Modals.Header> */}
          </Modal>
          <Notification />
          <main className="zervx_main  overflow-scroll">
            <div className="zervx_content overflow-scroll">
              <div className=" sticky top-0 z-50 bg-light ">
                <NavBar path="accept" bookingId={bookingId} />
              </div>
              <section className="header-outer">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-3">
                      {/* <div className="header-arrow">
                    <Link to={NavLinks.HOME}>
                      <svg
                        className="ola-svg ola-icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                        </g>
                      </svg>
                    </Link>
                  </div> */}
                    </div>
                    <div className="col-md-6 col-6">
                      <div className="header-logo text-center">
                        <h5>{bookingID}</h5>
                      </div>
                    </div>
                    <div className="col-md-3 col-3">
                      {/* <!-- <div className="header-login">
                        <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

                     </div> --> */}
                    </div>
                  </div>
                </div>
              </section>

              {/* <!-- booking_confirmation_outer --> */}
              <section className="location-form-outer">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="bookin-form">
                        {/* <form> */}
                        <div className="input-field-common">
                          <label>{language.PICKUP}</label>
                          {/* <input
                          type="text"
                          id="name"
                          placeholder="Enter Drop PickUp"
                          value={pickUpAddress}
                          readonly
                        /> */}
                          {/* <span>{pickup}</span> */}
                          <span className="md:text-md text-sm">
                            {bookingDeatils.pickUp}
                          </span>
                        </div>
                        <div className="input-field-common">
                          <label>{language.DROP}</label>
                          {/* <input
                          type="text"
                          id="name"
                          placeholder="Enter Drop Location"
                          value={dropAddress}
                          readonly
                        /> */}
                          <span className="md:text-md text-sm">
                            {bookingDeatils.drop}
                          </span>
                        </div>
                        <div className=" flex p-2 font-semibold text-center w-full">
                          <span>{language.INFO_PICKUP_POINT}</span>
                          <div className="px-2">
                            {Math.round(estmationTravelTime / 60) +
                              " Mins"}
                          </div>
                        </div>
                        {/* <input
                        type="submit"
                        id="submit"
                        value="Pickup arriving in 4min"
                      /> */}
                        {/* </form> */}
                        <div className="map_outer">
                          <div
                            ref={mapArea}
                            className="bg-white  h-56 rounded-lg m-1"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="booking_confirmation_outer">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="booking_confirmation_inner">
                        <div className="booking_confirmation_box">
                          <div className="icon_div">
                            <i className="fa fa-key" aria-hidden="true">
                              {<FiKey />}
                            </i>
                          </div>
                          <div className="content_div">
                            <span className="car_name">{language.OTP}</span>
                          </div>
                          <div className="last-div">
                            <span className="car_price">
                              {/* {bookedInformation.bookingOTP} */}
                              {bookingDeatils.bookingOTP}
                            </span>
                          </div>
                        </div>
                        <div className="booking_confirmation_box">
                          <div className="icon_div">
                            <img
                              src={bookingDeatils.categoryImage}
                              title=""
                              alt=""
                            />
                          </div>
                          <div className="content_div">
                            <span className="car_name">
                              {bookingDeatils.model}
                            </span>
                            <span className="car_type">
                              {bookingDeatils.vehicleCategory}
                            </span>
                          </div>
                          <div className="last-div">
                            <span className="font-bold ">
                              {/* {bookedInformation.vehicle.plateNumber} */}
                              {bookingDeatils.plateNumber}
                            </span>
                            {/* <span className=" font-semibold">
                          {bookingDeatils.model}
                        </span> */}
                            <span className="font-semibold">
                              {bookingDeatils.color}
                            </span>
                            {/*  Car Fare Amount or Car Number  */}
                          </div>
                        </div>
                        <div className="booking_confirmation_box">
                          <div className="icon_div">
                            {/* <img src={profile} title="" alt="" /> */}
                            <img
                              // src={bookedInformation.professional.avatar}
                              src={bookingDeatils.avatar}
                              title=""
                              alt=""
                            />
                          </div>
                          <div className="content_div">
                            <span className="drvier_name">
                              {bookingDeatils.firstName +
                                " " +
                                bookingDeatils.lastName}
                            </span>
                            <span className="rating py-2">
                              <i className=" flex" aria-hidden="true">
                                <div className="pr-2">
                                  {(bookingDeatils?.avgRating || 0).toFixed(2)}
                                </div>
                                {/* {bookingDeatils.avgRating.filter(() => (
                              <div className=" text-yellow-500">
                                {<FiStar />}
                              </div>
                            ))} */}
                                <div className=" text-yellow-500">
                                  {<FiStar />}
                                </div>
                              </i>
                            </span>
                          </div>
                          <div className="last-div flex justify-center bg-blue-900   align-middle items-center rounded-full p-2 py-3 text-white ">
                            <a
                              // href={`tel:${settings?.callCenterPhone}`}
                              href={`tel:${bookingDeatils?.professionalPhoneNumber?.number}`}
                              className=" font-semibol "
                            >
                              {/* {settings?.callCenterPhone}
                               */}
                              <FiPhoneCall className="text-white" />
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="booking_confirmation_inner">
                        {/*<div className="booking_confirmation_box">
                       <div className="icon_div">
                        <i className="fa fa-percent" aria-hidden="true">
                          {<FiPercent />}
                        </i>
                      </div>
                      <div className="content_div">
                        <span className="car_name">Apply Coupon</span>
                      </div> 
                    </div>*/}
                        {/* <div className="booking_confirmation_box">
                          <div className="icon_div">
                            <i className="fa fa-life-ring" aria-hidden="true">
                              {<FiLifeBuoy />}
                            </i>
                          </div>
                          <div className="content_div">
                            <span className="car_name">Support</span>
                          </div>
                        </div> */}
                      </div>

                      <div className="booking_confirmation_inner mb-5">
                        <div className="booking_confirmation_box cancel_div">
                          <div className="icon_div">
                            <i
                              className="fa fa-times-circle"
                              aria-hidden="true"
                            >
                              {<FiTriangle />}
                            </i>
                          </div>
                          <div
                            className="content_div cursor-pointer"
                            onClick={() => {
                              // cancelRide();
                              CancelReasion();
                              setModalShow(true);
                            }}
                          >
                            <span className="car_name">{language.CANCEL}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div
              className="zervx_rightimg"
              // style="background-image: url('images/bg_city.jpg');"
            >
              <img
                src={settings?.bookingAppImage}
                alt=""
                className="w-screen h-screen"
              />

              <div className="global-img-right">
                {/* <h2>
                Ride with <span>Comfort</span>
              </h2>
              <h2>
                Ride with <span>ZervX</span>
              </h2> */}
                <h2>{settings?.bookingAppHomePageMessage}</h2>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <SmallLoader />
      )}
    </>
  );
}
