import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../../utils/navLinks";
import axios from "axios";
import { useParams } from "react-router";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  FaUser,
  FaIdCard,
  FaPlus,
  FaAngleRight,
  //   FaIdCard,
} from "react-icons/fa";
import { RxCalendar } from "react-icons/rx";
import { MdVpnKey } from "react-icons/md";
import Modals from "react-bootstrap/Modal";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import A from "../../utils/urls.utils";
import useAdmin from "../hooks/useAdmin";

import { TextField } from "../common/TextFeild";
import { Button } from "react-bootstrap";
import { async } from "@firebase/util";
import { useFormik } from "formik";
import * as yup from "yup";
import { CONST_USER } from "../../utils/constantUtils";
import { CardDeatilscontext } from "../context/CardDeatilsContext";
import card from "../../assets/images/card_bg_image.png";

export default function CardDeatils() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { header } = useAdmin();
  const { viewBy } = useParams();
  const navigate = useNavigate();

  const { CardDeatilsData, setCardDeatilsData } =
    useContext(CardDeatilscontext);

  const [user, setUser] = useState();
  const [cardVerifyData, setCardVerifyData] = useState();
  const [isAddCard, setIsAddCard] = useState(
    CardDeatilsData?._id ? true : false
  );
  const [cardList, setCardList] = useState();
  const [show, setShow] = useState(false);
  const [popUpContent, setPopUpContent] = useState(true);
  const [removeCard, setRemoveCard] = useState(false);

  const general = {
    cardNumber: "",
    holderName: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: "",
  };

  useEffect(() => {
    userProfile();
    getCardlist();
  }, []);
  const userProfile = async () => {
    try {
      const { data } = await axios.post(
        A.HOST + A.USER_PROFILE_GET,
        {},
        header
      );
      // alert(JSON.stringify(data));
      setUser(data.data);
      //   console.log(data.data);
    } catch (err) {
      //   authFailure(err);
      // alert(err);
    }
  };
  const formik = useFormik({
    initialValues: general,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      cardNumber: yup
        .number()
        .min(16, "Maximum 16 number")
        // .max(18, "Maximum 18 numbers ")
        .required(language.REQUIRED),
      holderName: yup.string().required(language.REQUIRED),
      //   expiry: yup.string().required(language.REQUIRED),
      expiryMonth: yup
        .number("Only number")
        .min(2, "Maximum 2 days")
        .required(language.REQUIRED),
      expiryYear: yup
        .number("Only number")
        .min(2, "Maximum 2 days")
        .required(language.REQUIRED),
      cvv: yup
        .number("Only number")
        .min(3, "Maximum 3 CVV")
        .required(language.REQUIRED),
    }),
    onSubmit: async (e) => {
      // alert("card Verify");
      const payment = await axios.post(
        A.HOST + A.VERIFY_CARD_PAYMENT_GATWAY,
        {
          userType: CONST_USER,
          userId: user.userId,
          cardNumber: e.cardNumber,
          paymentGatway: user.paymentGateWay.gateWay,
          //   card: {
          //   cardNumber: e.cardNumber,
          holderName: e.holderName,
          expiryMonth: e.expiryMonth,
          expiryYear: e.expiryYear,
          cvv: e.cvv,
          //   },
        }, //userId
        header
      );
      // console.log("payment");
      // console.log(payment);
      // if(payment?.data?.code === 200){
      setCardVerifyData(payment.data);
      setShow(true);
      setPopUpContent(payment.message);
      // } else{
      //   setShow(true);
      //   setPopUpContent(payment.data.message);
      //   return;
      // }
      //   alert(JSON.stringify(payment.data.data.transactionId));
      //   return;
      if (payment) {
        try {
          // alert("card add start");

          const { data } = await axios.post(
            A.HOST + A.ADD_CARD_DEATILS,
            {
              cardNumber: e.cardNumber,
              holderName: e.holderName,
              expiryMonth: e.expiryMonth,
              expiryYear: e.expiryYear,
              cvv: e.cvv,
              transactionId: cardVerifyData?.data.transactionId,
              paymentGateWayCustomerId:
                cardVerifyData?.data.paymentGateWayCustomerId,
              userId: user?.userId,
              userType: CONST_USER,
              pin: e.cvv,
            },
            header
          );
          console.log(data);
          setShow(true);
          setPopUpContent(language.CARD_ADDED);
          // alert("card add sucess");

          window.location.reload(true);
        } catch (err) {
          // alert("card add err");

          alert(err);
        }
      }
    },
  });

  const removeCards = async () => {
    // console.log(CardDeatilsData);

    const { data } = await axios.post(
      A.HOST + A.REMOVE_CARD,
      {
        cardId: CardDeatilsData._id,
        // type: "",
        cardNumber: CardDeatilsData.cardNumber,
        expiryYear: CardDeatilsData.expiryYear,
        expiryMonth: CardDeatilsData.expiryMonth,
        holderName: CardDeatilsData.holderName,
        // currencyCode: "",
        // pin: "",
        isDefault: CardDeatilsData.isDefault,
        // paymentGateWayCustomerId: "",
      },
      header
    );
    // console.log("removeCards");
    // console.log(data);
    window.location.reload(true);
  };
  //   useEffect(() => {}, []);

  const getCardlist = async () => {
    const { data } = await axios.post(A.HOST + A.CET_CARD_LIST, {}, header);
    data.data.map((each) => {
      if (each.name === "CARD") {
        // alert(JSON.stringify(each.cards));
        console.log(each.cards);
        setCardList(each.cards);
      }
    });
    // console.log(data);
  };
  if (show) {
    setTimeout(() => {
      setShow(false);
    }, 3500);
  }
  return (
    <>
      <Modals show={show} onHide={show}>
        {/* <Modals.Header> */}
        <Modals.Title className="text-center">{popUpContent}</Modals.Title>
        {/* </Modals.Header> */}
      </Modals>
      <main class="zervx_main">
        <div class="zervx_content">
          <section class="header-outer">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-3 col-3  ">
                  <div class="header-arrow">
                    <Link
                      to={
                        viewBy === "booking"
                          ? NavLinks.RIDE_BOOKING
                          : viewBy === "wallet"
                          ? NavLinks.WALLET
                          : NavLinks.PROFILE + "/card/select"
                      }
                    >
                      <svg
                        class="ola-svg ola-icon  top-0"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div class="col-md-6 col-6 ">
                  <div class="header-logo text-center ">
                    <h5>{language.CARD_DETAILS}</h5>
                  </div>
                </div>
                <div class="col-md-3 col-3"></div>
              </div>
            </div>
          </section>

          {/* <!-- Receipt_START --> */}

          <section class="receipt_main_otr">
            <div class="container">
              <div class="row">
                {!isAddCard && (
                  <div>
                    {cardList && (
                      <div>
                        {cardList.map((each) => (
                          <div
                            className="flex justify-center cursor-pointer my-2"
                            onClick={() => {
                              // localStorage.setItem(
                              //   "CardDeatils",
                              //   JSON.stringify({
                              //     id: each._id,
                              //     cardNumber: each.cardNumber,
                              //     expiryMonth: each.expiryMonth,
                              //     expiryYear: each.expiryYear,
                              //     cvv: each.cvv,
                              //     // each,
                              //   })
                              // );
                              setCardDeatilsData(each);
                              // alert(JSON.stringify(each));
                              // if (viewBy === "booking") {
                              //   navigate(NavLinks.RIDE_BOOKING);
                              // } else if (viewBy === "wallet") {
                              //   navigate(NavLinks.WALLET);
                              // } else {
                              //   setRemoveCard(true);
                              //   setIsAddCard(false);

                              //   console.log(CardDeatilsData);
                              //   console.log(cardList[0].cardNumber);
                              //   // navigate(NavLinks.PROFILE + "/card/select");
                              // }
                            }}
                          >
                            <div className=" bg-gray-300 p-2 flex  justify-evenly col-lg-6 col-sm-12 rounded-xl">
                              <div className="flex items-center">
                                <FaIdCard />
                              </div>
                              <div>
                                {"XXXXXXXXXXX" + each.cardNumber.substr(13, 17)}
                              </div>
                              <div>
                                {each.expiryMonth + "/" + each.expiryYear}
                              </div>
                              <span
                                className={`${
                                  CardDeatilsData?._id === each._id
                                    ? "bg-black"
                                    : "bg-white"
                                }  p-1 h-1 rounded-full`}
                              ></span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {removeCard === false && (
                      <div
                        className="flex justify-center -ml-10 my-3 cursor-pointer"
                        onClick={(e) => {
                          if (isAddCard === false) {
                            setIsAddCard(true);
                            setRemoveCard(false);
                          } else {
                            setRemoveCard(false);
                            setIsAddCard(false);
                          }
                        }}
                      >
                        <div className="flex justify-normal gap-3 items-center  rounded-xl ">
                          <div className="bg-gray-200 rounded-full p-2 ">
                            <FaPlus className="" />
                          </div>
                          <div
                          //className="grid grid-rows-2"
                          >
                            {/* <span className=" text-start font-semibold text-gray-400">
                          Tap here to
                        </span> */}
                            <div className="text-start">
                              <span>{language.INFO_ADD_CARD_DETAILS}</span>
                            </div>
                          </div>
                          {/* <div className="flex items-center  text-end ">
                        <div className=" p-2 shadow-md bg-white rounded-full">
                          <FaAngleRight className="" />
                        </div>
                      </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {isAddCard || removeCard ? (
                  <div class="col-lg-12 col-sm-12">
                    {/* <div class="receipt_content">
                  <div class="logo_div">
                    <img src="images/zervxpng.png" alt="" title="" />
                  </div>
                  <div class="price_div">
                    <h2> $5.00</h2>
                  </div>
                  <div class="cash_outer">
                    <div class="cash_title">ZH-809978458734</div>
                    <div class="cash_title">Cash</div>
                  </div>
                  <div class="table_otr">
                    <table>
                      <tbody>
                        <tr>
                          <td>Trip Fee</td>
                          <td>$50</td>
                        </tr>
                        <tr>
                          <td>Services Tax</td>
                          <td>$0.00</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total Bill</td>
                          <td>$50.00</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div class="date_time">11/12/2020, 09:04</div>

                  <div class="barcode_div">
                    <img src="images/barcode.png" alt="" title="" />
                  </div>
                </div> */}

                    {/* <div className=" relative  bg-card w-full md:h-96 h-56">
                     

                      <div className=" absolute top-6 right-10 w-10 h-10 ">
                        <img src={settings?.mobileLogo} alt="" className="" />
                      </div>

                      <div className=" absolute top-20 right-4 ">
                        <h5 className="text-gray-400 pr-4 md:text-xl text-sm">
                          {formik.values.holderName
                            ? formik.values.holderName
                            : removeCard
                            ? cardList[0].holderName
                            : isAddCard && "Card holder Name"}
                        </h5>
                      </div>

                      <div className=" absolute md:top-40  top-28 right-4 ">
                        <h5 className="text-gray-400 pr-4 md:text-xl text-sm">
                          {formik.values.cardNumber
                            ? formik.values.cardNumber
                            : removeCard
                            ? cardList[0].cardNumber
                            : isAddCard && "Card Number"}
                        </h5>
                      </div>

                      <div className=" absolute md:top-56 top-40 left-4 ">
                        <h5 className="text-gray-400 pr-4 md:text-xl text-sm">
                          {formik.values.expiryMonth && formik.values.expiryYear
                            ? formik.values.expiryMonth +
                              "/" +
                              formik.values.expiryYear
                            : removeCard
                            ? cardList[0].expiryMonth +
                              "/" +
                              cardList[0].expiryYear
                            : isAddCard && "Valid Till"}
                        </h5>
                      </div>

                      <div className=" absolute md:top-56 top-40 left-56 ">
                        <h5 className="text-gray-400 pr-4 md:text-xl text-sm">
                          {formik.values.cvv ? formik.values.cvv : "CVV"}
                        </h5>
                      </div>
                    </div> */}
                    <div className="pt-2 mt-5 px-3">
                      <h5>{language.BASIC_DETAILS}</h5>
                    </div>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2 py-2 px-3">
                      <div>
                        <TextField
                          placeholder={language.CARD_NUMBER}
                          icon={<FaIdCard />}
                          value={
                            formik.values.cardNumber
                              ? formik.values.cardNumber
                              : removeCard
                              ? cardList[0].cardNumber
                              : isAddCard && ""
                          }
                          change={(e) => {
                            const limit = 19;
                            formik.setFieldValue(
                              "cardNumber",
                              e.slice(0, limit)
                            );
                            // formik.setFieldValue("cardNumber", e);
                          }}
                          error={formik.errors.cardNumber}
                          // minlength="5"
                          type="number"
                        />
                      </div>
                      <div className="">
                        <TextField
                          placeholder={language.CARD_HOLDER_NAME}
                          icon={<FaUser />}
                          value={
                            formik.values.holderName
                              ? formik.values.holderName
                              : removeCard
                              ? cardList[0].holderName
                              : isAddCard && ""
                          }
                          change={(e) => {
                            formik.setFieldValue("holderName", e);
                          }}
                          error={formik.errors.holderName}
                        />
                      </div>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-2 py-2 px-3">
                      <div className="flex ">
                        <TextField
                          monthRight
                          placeholder={"MM"}
                          icon={<RxCalendar />}
                          value={
                            formik.values.expiryMonth
                              ? formik.values.expiryMonth
                              : removeCard
                              ? cardList[0].expiryMonth
                              : isAddCard && ""
                          }
                          change={(e) => {
                            //   formik.setFieldValue(
                            //     "expiry",
                            //     format(new Date(e), "d MMM ")
                            //   );
                            const limit = 2;

                            formik.setFieldValue(
                              "expiryMonth",
                              e <= 12 && e.slice(0, limit)
                            );
                          }}
                          error={formik.errors.expiryMonth}
                          type="number"
                          // type="text"
                          // maxlength={2}
                          // width={24}
                        />

                        <TextField
                          monthLeft
                          placeholder={"YY"}
                          icon={"/"}
                          value={
                            formik.values.expiryYear
                              ? formik.values.expiryYear
                              : removeCard
                              ? cardList[0].expiryYear
                              : isAddCard && ""
                          }
                          change={(e) => {
                            //   formik.setFieldValue(
                            //     "expiry",
                            //     format(new Date(e), "d MMM ")
                            //   );
                            const limit = 4;
                            formik.setFieldValue(
                              "expiryYear",
                              e.slice(0, limit)
                            );
                          }}
                          error={formik.errors.expiryYear}
                          // type="text"
                          type="number"
                          // maxlength={2}
                          // width={120}
                        />
                        {/* <input
                        type="text"
                        id="number"
                        name="number"
                        maxlength="2"
                      /> */}
                      </div>
                      <div className="">
                        <TextField
                          placeholder=" CVV"
                          icon={<MdVpnKey />}
                          value={formik.values.cvv}
                          change={(e) => {
                            const limit = 3;
                            formik.setFieldValue("cvv", e.slice(0, limit));
                          }}
                          type="number"
                          // maxlength={3}
                          error={formik.errors.cvv}
                        />
                      </div>
                    </div>
                    <div className="text-center py-2 pb-5 w-full px-3">
                      {removeCard ? (
                        <button
                          onClick={() => {
                            removeCards();
                          }}
                        >
                          {language.REMOVE_CARD}
                        </button>
                      ) : (
                        <div className=" flex justify-between items-center gap-2">
                          <button
                            className="bg-gray-200 text-black w-full py-2 rounded-lg"
                            onClick={() => {
                              setIsAddCard(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="bg-black text-white w-full py-2 rounded-lg"
                            onClick={formik.handleSubmit}
                          >
                            {language.ADD_CARD}
                          </button>
                        </div>
                      )}
                    </div>

                    {removeCard === false && (
                      <div className=" bg-green-100 rounded-xl my-2 md:mx-5 mb-5">
                        <p className="text-gray-700 p-2 text-sm text-center">
                          {language.ALERT_ADD_CARD}
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </div>
        <div className="zervx_rightimg">
          <img
            src={settings?.bookingAppImage}
            alt=""
            className="w-screen h-screen"
          />

          <div className="global-img-right">
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}
