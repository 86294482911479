import { atom } from "recoil";

export const settingsAtom = atom({
  key: "setttingsAtom",
  default: null,
  // default: settingsJSON,
});
export const navBarSelectedKey = atom({
  key: "navBarSelectedKey",
  default: false,
});
