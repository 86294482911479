const constantUtils = {};

// Key
constantUtils.CONST_KEY = "my-32-character-ultra-secure-and-ultra-long-secret";
//
constantUtils.CONST_PRODUCTION = "PRODUCTION";
constantUtils.CONST_WEBAPP = "WEBAPP";
//
constantUtils.CONST_ANDROID_OS = "A0S";
constantUtils.CONST_IOS = "I0S";
constantUtils.CONST_WEB_OS = "W0S";
// notification Types
constantUtils.CONST_PUSH = "PUSH";
constantUtils.CONST_EMAIL = "EMAIL";
constantUtils.CONST_SMS = "SMS";

// NOTICICATION CATEGORIES
constantUtils.CONST_ALPHABETICALORDER = "ALPHABETICALORDER";
constantUtils.CONST_CITY = "CITY";

// COMMON FOR FULL APP
constantUtils.CONST_USER = "USER";
constantUtils.CONST_PROFESSIONAL = "PROFESSIONAL";

//  PAYMENT TYPES
constantUtils.CONST_CARD = "CARD";
constantUtils.CONST_CASH = "CASH";
constantUtils.CONST_WALLET = "WALLET";

// PAYOUTOPTIONS
constantUtils.CONST_DISPLAYINFO = "DISPLAYINFO";

//
constantUtils.CONST_ACTIVE = "ACTIVE";
constantUtils.CONST_DROPDOWN = "DROPDOWN";
//
constantUtils.CONST_WEEKLY = "WEEKLY";
constantUtils.CONST_MONTHLY = "MONTHLY";
constantUtils.CONST_YEARLY = "YEARLY";

// SMS Providers
constantUtils.CONST_AFRO = "AFRO";
constantUtils.CONST_FIREBASE = "FIREBASE";
constantUtils.CONST_TWILIO = "TWILIO";

// Roles
constantUtils.CONST_ADMIN = "ADMIN";
constantUtils.CONST_DEVELOPER = "DEVELOPER";
constantUtils.CONST_COORPERATEOFFICE = "COORPERATEOFFICE";
constantUtils.CONST_CORPORATEOFFICER = "CORPORATEOFFICER";

//
constantUtils.CONST_STARTED = "STARTED";
constantUtils.CONST_ARRIVED = "ARRIVED";
constantUtils.CONST_ACCEPTED = "ACCEPTED";
constantUtils.CONST_ENDED = "ENDED";
//--------------------
constantUtils.CONST_TELEPORT = "TELEPORT";
constantUtils.CONST_NONE = "NONE";
constantUtils.CONST_TRIPS = "TRIPS";
constantUtils.CONST_PACKAGE = "PACKAGE";
constantUtils.CONST_SHIFTINGS = "SHIFTINGS";
constantUtils.CONST_ATOZ = "ATOZ";
constantUtils.CONST_EATS = "EATS";
constantUtils.CONST_ESSENTIALMART = "ESSENTIALMART";
constantUtils.CONST_RENTALS = "RENTALS";
constantUtils.CONST_PARKING = "PARKING";
constantUtils.CONST_SECUREME = "SECUREME";
//
constantUtils.CONST_ADMINISTRATORS = "ADMINISTRATORS";
//
constantUtils.CONST_MILES = "MILES";
constantUtils.CONST_MILES_VALUE = 1610;
constantUtils.CONST_KM = "KM";
constantUtils.CONST_KM_VALUE = 1000;
// Dashboard

constantUtils.CONST_ALL = "ALL";
constantUtils.CONST_INSTANT = "INSTANT";
constantUtils.CONST_SCHEDULE = "SCHEDULE";
constantUtils.CONST_CANCELLED = "CANCELLED";

// Rides Category
constantUtils.CONST_RIDE = "RIDE";
constantUtils.CONST_INTERCITYRIDE = "INTERCITYRIDE";
constantUtils.CONST_PICKUPOUTSIDERIDE = "PICKUPOUTSIDERIDE";
constantUtils.CONST_DROPOUTSIDERIDE = "DROPOUTSIDERIDE";
constantUtils.CONST_PICKUPANDDROPOUTSIDERIDE = "PICKUPANDDROPOUTSIDERIDE";
constantUtils.CONST_CORPORATERIDE = "CORPORATERIDE";

constantUtils.CONST_FLAT = "FLAT";
constantUtils.CONST_PERCENTAGE = "PERCENTAGE";

//CAB BOOKING

constantUtils.CONST_DAILYTRIP = "DAILYTRIP";
constantUtils.CONST_USERCANCELLED = "USERCANCELLED";
constantUtils.CONST_EXPIRED = "EXPIRED";

module.exports = constantUtils;
