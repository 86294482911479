module.exports = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  ARCHIEVE: "ARCHIVE",
  PROFESSIONAL: "PROFESSIONAL",
  USER: "USER",
  RESPONSE_OFFICER: "RESPONSEOFFICER",
  PROFILE_DOCUMENTS: "PROFILEDOCUMENTS",
  DRIVER_DOCUMENTS: "DRIVERDOCUMENTS",
  VEHICLE_DOCUMENTS: "VEHICLEDOCUMENTS",
  CORPORATE: "COORPERATEOFFICE",
  ADMIN: "ADMIN",
  DEVELOPMENT: "DEVELOPMENT",
  PRODUCTION: "PRODUCTION",
  LIVE: "LIVE",
  SANDBOX: "SANDBOX",
  RIDES: "ride",
  DELIVERY: "delivery",
  INCOMPLETE: "INCOMPLETE",
  ATTENDED: "ATTENDED",
  VERIFIED: "VERIFIED",
  UNVERIFIED: "UNVERIFIED",
  YES: "YES",
  NO: "NO",
  ARRIVED: "ARRIVED",
  ACCEPTED: "ACCEPTED",
  STARTED: "STARTED",
  ENDED: "ENDED",
  INSTANT: "INSTANT",
  SCHEDULE: "SCHEDULE",
  AWAITING: "AWAITING",
  EXPIRED: "EXPIRED",
  USERCANCELLED: "USERCANCELLED",
  USERDENIED: "USERDENIED",
  PROFESSIONALCANCELLED: "PROFESSIONALCANCELLED",
  IMAGE_LOCAL: true,
  SHOW_SECURITY: this.mode === "development" ? true : false,
  UPLOADED: "UPLOADED",
  REPORT: "REPORT",
  NEW: "NEW",
  CLOSED: "CLOSED",
  RIDE_RETRY_WAIT: 5000,
  mode: process.env.REACT_APP_CLIENT_KEY || "localhost", // starmov berarkrays, jetride, localhost, development, demo, trail, zayride , zayride_development, quickzy, pamworld, digito, olympiahld
};
