import React, { useEffect, useState } from "react";
import { FiChevronDown, FiMinus } from "react-icons/fi";
import CountryCodes from "../../utils/CountryCodes.json";
import U from "../../utils/urls.utils";
import OutsideClick from "react-outside-click-handler";
import useSettings from "../hooks/useSettings";

export const CountryCodesPicker = ({
  width = "full",
  change = () => {},
  defaultValue = "",
  icon = <FiChevronDown />,
  placeholder = "",
  margin = 0,
  error = "",
  readOnly = false,
  className,
  ...rest
}) => {
  const { defaultDialCode } = useSettings();
  const initalValue = defaultDialCode ?? "+91";
  const items = CountryCodes.sort(
    (a, b) => a.dial_code?.length - b.dial_code?.length
  ).map((country) => ({
    name: country.name,
    code: country.code,
    value: country.dial_code,
    search: country.name + " " + country.dial_code,
  }));
  const [isOpen, setOpen] = useState(false);
  const [text, setText] = useState("");
  const inputRef = React.useRef(null);
  const [filtered, setFiltered] = useState([]);
  const [finalCode, setFinalCode] = useState(
    defaultValue === "" ? initalValue : defaultValue
  );
  const [finalCountry, setFinalCountry] = useState("");

  useEffect(() => {
    const temp = items.filter((item) => item.value === finalCode);
    if (temp?.length > 0) {
      setFinalCountry(temp[0].code.toLowerCase());
      if (finalCode === initalValue) change(initalValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    // text?.length > 0 && setFiltered(items.filter((item) => item.search.toUpperCase().includes(text.toUpperCase())));
    if (text?.length > 0) {
      // IF NUMBER
      if (text.startsWith("+") === false && parseInt(text)) {
        setFiltered(items.filter((item) => item.value?.includes("+" + text)));
        if (items.filter((item) => item.value === "+" + text)?.length === 1) {
          change("+" + text);
          setFinalCode("+" + text);
          setFinalCountry(items.find((item) => item.value === "+" + text).code);
          setOpen(false);
        }
      } else if (text.startsWith("+")) {
        setFiltered(items.filter((item) => item.value?.includes(text)));
        if (items.filter((item) => item.value === text)?.length === 1) {
          change(text);
          setFinalCode(text);
          setFinalCountry(items.find((item) => item.value === text).code);
          setOpen(false);
        }
      }
      // IF STRING
      else {
        setFiltered(
          items.filter((item) =>
            item.name.toUpperCase().startsWith(text.toUpperCase())
          )
        );
        if (
          items.filter((item) =>
            item.name.toUpperCase().startsWith(text.toUpperCase())
          )?.length === 1
        ) {
          change(
            items.filter((item) =>
              item.name.toUpperCase().startsWith(text.toUpperCase())
            )[0].value
          );
          setFinalCode(
            items.filter((item) =>
              item.name.toUpperCase().startsWith(text.toUpperCase())
            )[0].value
          );
          setFinalCountry(
            items.filter((item) =>
              item.name.toUpperCase().startsWith(text.toUpperCase())
            )[0].code
          );
          setOpen(false);
        }
      }
    }
  }, [text]);

  const countryClick = (final) => {
    change(final.value);
    setFinalCode(final.value);
    setFinalCountry(final.code);
    setOpen(false);
  };

  const clearFinals = () => {
    setFinalCode("");
    setFinalCountry("");
    setText("");
    setTimeout(() => {
      inputRef.current.focus();
    }, 200);
  };

  return (
    <div
      className={` text-center  w-fit mr-2  
        
      `}
      // ${
      //   document.getElementsByTagName("html")[0].getAttribute("dir") === "ltr"
      //     ? `mr-${margin}`
      //     : `ml-${margin}`
      // }
    >
      {finalCode?.length > 0 && (
        <div
          onClick={readOnly === false ? clearFinals : null}
          className={`  p-2 focus:outline-none bg-gray-200 rounded-lg text-center flex`} // dark:bg-gray-900
        >
          <img
            className="h-6"
            src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${finalCountry.toLowerCase()}.svg`}
            alt=""
          />
          <span className="px-2">{finalCode}</span>
          {/* <button className="bg-blue-800 p-1 rounded-full text-white text-sm" onClick={clearFinals}><FiMinus/></button> */}
        </div>
      )}
      {finalCode?.length === 0 && (
        <OutsideClick
          onOutsideClick={() => {
            // setFinalCode(defaultValue);
            setTimeout(() => {
              setOpen(false);
              // const temp = items.filter((item) => item.value === defaultValue);
              // if (temp?.length > 0) {
              //   setFinalCountry(temp[0].code.toLowerCase());
              // }
            }, 300);
          }}
        >
          <input
            onChange={(e) => setText(e.target.value)}
            // onBlur={() => setOpen(false)}
            // inputmode="numeric"
            onKeyDown={(e) => e.key === 13 && e.preventDefault()}
            placeholder={placeholder}
            type={"text"}
            onFocus={() => setOpen(true)}
            ref={inputRef}
            // className={` w-${width} ${className} flex p-2 px-2  mr-4 h-12 w-20  text-gray-400  focus:outline-none bg-gray-100 rounded-xl  border-b-2 border-gray-100 dark:border-gray-800 focus:border-blue-800 ${
            //   //dark:bg-gray-900
            //   error !== "" &&
            //   "border-2  placeholder-red-500 dark:placeholder-white shadow_red"
            // }`}
            className={`  p-2 w-[90px] focus:outline-none rounded-lg  bg-gray-200 text-center ${
              //dark:bg-gray-900
              error !== "" &&
              "border-2  placeholder-red-500 dark:placeholder-white  "
            }`}
          />
        </OutsideClick>
      )}
      {isOpen && (
        <ul
          className="fixed flex flex-col z-20 rounded-lg max-h-44 shadow-xl overflow-y-scroll text-black bg-gray-50"
          style={{
            zIndex: 30,
          }}
        >
          {text?.length > 0 &&
            filtered.map((fil) => (
              <div
                onClick={() => countryClick(fil)}
                key={fil.code}
                // className="border-b-1 hover:bg-gray-100 dark:bg-gray-900 border-gray-200 cursor-pointer flex items-center bg-white px-2 py-2 text-sm"
                className="p-2 flex items-center focus:outline-none bg-gray-50 mr-4 h-12 text-black"
              >
                <img
                  className="h-5 w-5"
                  src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${fil.code.toLowerCase()}.svg`}
                  alt=""
                />
                <div className="mx-2 dark:text-gray-300 text-center text-black">
                  {fil.value} {fil.name}
                </div>
              </div>
            ))}
        </ul>
      )}
      {error != "" && (
        <div className="flex">
          <div className="mx-2">
            {error && (
              <p
                className="text-red-600 dark:text-red-500 text-sm mt-1 "
                style={{ fontSize: 11 }}
              >
                {error}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
